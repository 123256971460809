import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

import { Fragment } from "react";
import { useCasesData } from "../../useCasesData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OurWorkWidget() {
  SwiperCore.use([Navigation, Autoplay]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items = useCasesData();

  return (
    <Fragment>
      <div className="mx-auto w-full bg-green-300 m-4 p-4">
        <Swiper
          className="h-auto bg-green-300"
          slidesPerView={1}
          modules={[Autoplay]}
          autoplay={{ delay: 5000 }}
          spaceBetween={200}
        >
          {items.map((item, index) => (
            <SwiperSlide
              className="h-full flex-row justify-center"
              onClick={(e) =>
                navigate(`/our-work/detail/${item.slug}`)
              }
              key={index}
            >
              <img
                className="m-auto w-full max-w-sm h-full rounded-xl"
                src={item.image_cover ?? require("../../assets/img/blog-default.png")}
                alt="cover"
              />
              <div className="text-ablue-900 m-4 font-bold text-2xl">
                {item.name}
              </div>
              <div className="text-agray-500 text-base line-clamp-3 m-4">
                {t(item.challenge)}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Fragment>
  );
}
