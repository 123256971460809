import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Ready({ noClick }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="flex items-center justify-center  m-auto">
    <div className="mr-auto ml-auto my-20 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px] lg:mx-36 mx-auto bg-ablue-600 py-16 xl:rounded-3xl lg:rounded-3xl md:rounded-3xl">
      <div className="m-auto px-4 md:px-16 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl grid grid-cols-12">
        <div className="col-span-12 text-white text-left md:col-span-8">
          <span className="text-md tracking-widest font-sans uppercase">
            {t("contact")}
          </span>
          <h2 className="col-span-12 text-5xl font-bold">{t("ready")}</h2>

          <p className="col-span-12 font-light mt-4 text-lg xl:w-1/2 lg:w-1/2 md:w-3/4">
            {t("ready-desc")}
          </p>
        </div>
        <div className="flex mt-4 col-span-12 md:place-self-end md:col-span-4">
          <Link
            to={
              noClick
                ? ""
                : currentLanguage === "en"
                ? "/contact"
                : "/es/contact"
            }
            className="hover:bg-agray-100 w-44 py-[17px] text-center text-sm font-manrope font-bold rounded-[28px] text-ablue-600 bg-white"
          >
            {t("contact")}
          </Link>
        </div>
      </div>
    </div>
    </div>
  );
}
