import { Fragment, useEffect, useState } from "react";

import CrushedCover from "../assets/img/caseStudies/crushed-cover.png";
import CrushedProblems from "../assets/img/caseStudies/crushed-problems.png";
import CrushedResults from "../assets/img/caseStudies/crushed-results.png";
import DigitabCover from "../assets/img/caseStudies/ditab-cover.png";
import DigitabProblems from "../assets/img/caseStudies/ditab-problems.png";
import DigitabResults from "../assets/img/caseStudies/ditab-results.png";
import FluxCover from "../assets/img/caseStudies/flux-cover.png";
import FluxProblems from "../assets/img/caseStudies/flux-problems.png";
import FluxResults from "../assets/img/caseStudies/flux-results.png";
import GarreCover from "../assets/img/caseStudies/garre-cover.png";
import GarreProblems from "../assets/img/caseStudies/garre-problems.png";
import GarreResults from "../assets/img/caseStudies/garre-results.png";
import HaigCover from "../assets/img/caseStudies/haig-cover.png";
import HaigProblems from "../assets/img/caseStudies/haig-problems.png";
import HaigResults from "../assets/img/caseStudies/haig-results.png";
import { Helmet } from "react-helmet";
import Loading from "./shared/Loading";
import OurWorkWidget from "./our-work/OurWorkWidget";
import PoscoCover from "../assets/img/caseStudies/posco-cover.png";
import PoscoProblems from "../assets/img/caseStudies/posco-problems.png";
import PoscoResults from "../assets/img/caseStudies/posco-results.png";
import Ready from "./Ready";
import ZplendidCover from "../assets/img/caseStudies/zplendid-cover.png";
import ZplendidProblems from "../assets/img/caseStudies/zplendid-problems.png";
import ZplendidResults from "../assets/img/caseStudies/zplendid-results.png";
import dogItCover from "../assets/img/caseStudies/dogit-cover.png";
import dogItProblems from "../assets/img/caseStudies/dogit-problems.png";
import dogItResults from "../assets/img/caseStudies/dogit-results.png";
import nativisCover from "../assets/img/caseStudies/nativis-cover.png";
import nativisProblems from "../assets/img/caseStudies/nativis-problems.png";
import nativisResults from "../assets/img/caseStudies/nativis-results.png";
import superPorteroCover from "../assets/img/caseStudies/sp-cover.png";
import superPorteroProblems from "../assets/img/caseStudies/sp-problems.png";
import superPorteroResults from "../assets/img/caseStudies/sp-results.png";
import { useTranslation } from "react-i18next";

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

export default function OurWork(props) {
  const { t, i18n } = useTranslation();

  const case_studies1 = shuffleArray([
    {
      data: {
        name: "Super Portero",
        image_cover: superPorteroCover,
        image_problems: superPorteroProblems,
        image_results: superPorteroResults,
        challenge: t("sp-challenge"),
        solution: t("sp-solution"),
        outcome: t("sp-outcome"),
        slug: "super-portero",
      },
    },
    {
      data: {
        name: "Dog it",
        image_cover: dogItCover,
        image_problems: dogItProblems,
        image_results: dogItResults,
        challenge: t("dogit-challenge"),
        solution: t("dogit-solution"),
        outcome: t("dogit-outcome"),
        slug: "dog-it",
      },
    },
    {
      data: {
        name: "Flux",
        image_cover: FluxCover,
        image_problems: FluxProblems,
        image_results: FluxResults,
        challenge: t("flux-challenge"),
        solution: t("flux-solution"),
        outcome: t("flux-outcome"),
        slug: "flux",
      },
    },
    {
      data: {
        name: "Howamigoing?",
        image_cover: HaigCover,
        image_problems: HaigProblems,
        image_results: HaigResults,
        challenge: t("haig-challenge"),
        solution: t("haig-solution"),
        outcome: t("haig-outcome"),
        slug: "howamigoing",
      },
    },
    {
      data: {
        name: "Zplendid",
        image_cover: ZplendidCover,
        image_problems: ZplendidProblems,
        image_results: ZplendidResults,
        challenge: t("zplendid-challenge"),
        solution: t("zplendid-solution"),
        outcome: t("zplendid-outcome"),
        slug: "zplendid",
      },
    },
  ]);

  const case_studies2 = shuffleArray([
    {
      data: {
        name: "Nativis Projex",
        image_cover: nativisCover,
        image_problems: nativisProblems,
        image_results: nativisResults,
        challenge: t("nativis-challenge"),
        solution: t("nativis-solution"),
        outcome: t("nativis-outcome"),
        slug: "nativis-projex",
      },
    },
    {
      data: {
        name: "Garré",
        image_cover: GarreCover,
        image_problems: GarreProblems,
        image_results: GarreResults,
        challenge: t("garre-challenge"),
        solution: t("garre-solution"),
        outcome: t("garre-outcome"),
        slug: "garre",
      },
    },
    {
      data: {
        name: "Crushed!?",
        image_cover: CrushedCover,
        image_problems: CrushedProblems,
        image_results: CrushedResults,
        challenge: t("crushed-challenge"),
        solution: t("crushed-solution"),
        outcome: t("crushed-outcome"),
        slug: "crushed",
      },
    },
    {
      data: {
        name: "Digitab",
        image_cover: DigitabCover,
        image_problems: DigitabProblems,
        image_results: DigitabResults,
        challenge: t("digitab-challenge"),
        solution: t("digitab-solution"),
        outcome: t("digitab-outcome"),
        slug: "digitab",
      },
    },
    {
      data: {
        name: "Posco",
        image_cover: PoscoCover,
        image_problems: PoscoProblems,
        image_results: PoscoResults,
        challenge: t("posco-challenge"),
        solution: t("posco-solution"),
        outcome: t("posco-outcome"),
        slug: "posco",
      },
    },
  ]);

  const [loading, setLoading] = useState(true);
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (case_studies1 && case_studies2) {
      setLoading(false);
    }
  }, [case_studies1, case_studies2]);

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <Fragment>
      <Helmet>
        <title>{t("our-work")} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("our-work-desc")} />
        <meta property="og:title" content={t("our-work")} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("our-work-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {!loading ? (
        <div className="relative pt-44 m-auto bg-white ">
          <div className="m-auto top-0 w-5/6 text-center">
            <h1 className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t("our-work")}
            </h1>
            <h2 className="text-4xl xl:text-5xl text-nblue-900 font-bold">
              {t("products-difference")}
            </h2>
            <p className="font-light mt-4  text-lg text-agray-500 m-auto xl:w-1/2 lg:w-1/2 md:w-1/2">
              {t("our-work-desc")}
            </p>
          </div>
          <div className="hidden md:block">
            <div className="mx-6 pt-16 xl:mx-20  lg:mx-20 lg:mx-20">
              <div className="mx-auto lg:px-16 grid grid-cols-2 lg:gap-3">
                <div
                  className={`col-span-2 md:col-span-1 m-auto grid mt-[200px]`}
                >
                  {case_studies1.map((item, index) => (
                    <a
                      href={
                        currentLanguage === "en"
                          ? `/our-work/detail/${item.data.slug}`
                          : `/es/our-work/detail/${item.data.slug}`
                      }
                      key={index}
                    >
                      <img
                        className="mx-auto mt-16 w-80 xl:w-[440px] xl:h-[470px] bg-nblue-300 rounded-2xl "
                        src={item.data.image_cover}
                        alt={item.data.name}
                      />
                      <h2 className="pt-8 text-5xl text-left text-nblue-900 font-bold">
                        {item.data.name}
                      </h2>
                      <p
                        className="font-light mt-4 xl:w-[440px] text-left text-lg text-agray-500 m-auto"
                        style={{
                          WebkitLineClamp: 2,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {t(item.data.challenge)}
                      </p>
                    </a>
                  ))}
                </div>
                <div className={`col-span-2 md:col-span-1 m-auto mt-0 grid`}>
                  {case_studies2.map((item, index) => (
                    <a
                      href={
                          currentLanguage === "en"
                            ? `/our-work/detail/${item.data.slug}`
                            : `/es/our-work/detail/${item.data.slug}`
                      }
                      key={index}
                    >
                      <img
                        className="mx-auto mt-16 w-80 xl:w-[440px] xl:h-[470px] bg-nblue-300 rounded-2xl "
                        src={item.data.image_cover}
                        alt={item.data.name}
                      />
                      <h2 className="pt-8 text-5xl text-left text-nblue-900 font-bold">
                        {item.data.name}
                      </h2>
                      <p
                        className="font-light mt-4 xl:w-[440px] text-left text-lg text-agray-500 m-auto"
                        style={{
                          WebkitLineClamp: 2,
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {t(item.data.challenge)}
                      </p>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="block md:hidden">
            <OurWorkWidget></OurWorkWidget>
          </div>
          <Ready></Ready>
        </div>
      ) : (
        <div className="relative pt-44 m-auto bg-white ">
          <Loading></Loading>
        </div>
      )}
    </Fragment>
  );
}
