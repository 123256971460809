import education_icon from "../assets/img/technology/ic-education.png";
import finance_icon from "../assets/img/technology/ic-finance.png";
import healthcare_icon from "../assets/img/technology/ic-healthcare.png";
import { useTranslation } from "react-i18next";

export default function IndustryComponent() {
  const { t } = useTranslation();


  const industries = [{
    icon: healthcare_icon,
    name: "health-care",
    desc: t("health-care-description"),
    },
    {
        icon: finance_icon,
        name: "finance",
        desc: t("finance-description"),
    },
    {
        icon: education_icon,
        name: "education",
        desc: t("education-description"),
    },
  ];

  return (
    <div className="mx-4 lg:mx-40 tv:mx-96 px-4 lg:px-0 mt-16 pt-16">
      <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
        {t("industries")}
      </span>
      <h2 className="text-5xl text-nblue-900 font-bold">
        {t("industry-title")}
      </h2>
      <div className="relative">
        <div className="mt-10 mx-auto grid grid-cols-1 md:grid-cols-2 lg:gap-8">
          {industries.map((item, index) => (
            <div className="py-6 w-full" key={index}>
              <div className="h-12 w-12">
                <img
                  className="w-fit h-full "
                  src={item.icon}
                  alt="icon Passion"
                />
              </div>
              <div className="mt-6">
                <h3 className="text-2xl text-nblue-900 font-bold">
                  {t(item.name)}
                </h3>
                <p className="font-light mt-4  text-lg text-agray-500">
                  {item.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
