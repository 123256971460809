import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    home: "Home",
                    "web-service": "Web Development",
                    "mobile-service": "Mobile Development",
                    "nearshore-services": "Nearshore Development",
                    "s-p-s": "STUNNING, PERFORMANT AND SAFE",
                    "digital-products": "We transform your ideas into digital business.",
                    "we-build-tecnology": "Leverage our 10 years of tech expertise to accelerate your growth. Partner with us to deliver beautiful and fully functional products to your users. Discover how we've helped other startups grow and secure millions in funding.",
                    "next-project": "Let's begin",
                    "book-a-call": "Book a free consultation",
                    or: "or",
                    "products-delivered": "Products delivered and counting",
                    "trusted-by": "TRUSTED BY",
                    services: "Services",
                    "what-we-do": "What we do",
                    "what-we-do-desc": "We propel our client's success with software solutions, through a transparent, four-step process: Listen, Plan, Develop, and Refine. At Alluxi. Finally, we refine the product through extensive testing and iterative improvements, ensuring that it meets the highest quality and performance standards. At Alluxi, we are committed to amplifying our clients’ efficiency, reach, and profitability by delivering cutting-edge software solutions that empower them to succeed in today’s competitive digital landscape.",
                    "custom-web-develop": "Custom Web Development",
                    "custom-web-develop-desc": "Web development allows businesses looking for a consistent and flexible experience between different platforms.",
                    "web-title": "Tailored Web Development Services",
                    "web-subtitle": "Web solutions that drive business growth and delight users",
                    "web-hero-title": "Tailored web development that fuels your business growth",
                    "web-hero-subtitle": "Turning unique ideas into outstanding digital experiences",
                    "web-content": "Welcome to Alluxi! We're not just code enthusiasts, we're your partners in turning your vision into reality. Specializing in Python, Django, and React, we build powerful web solutions tailored to your unique needs. We've worked with diverse industries - Aviation, Retail, Healthcare, Education, and more - and brought innovative and robust web experiences to life. Our passion lies in understanding your challenges, dreaming big, and crafting a digital journey that not only meets your objectives but also enchants your users. Let's create something remarkable together!",
                    "custom-mobile-develop": "Custom Mobile Development",
                    "custom-mobile-develop-desc": "We create tailored apps to that enhance engagement, accessibility, and real-time connectivity. Transform your business in the mobile era with us!",
                    "mobile-subtitle": "Create mobile experiences that captivate and convert",
                    "mobile-desc": "95% of Americans own a cellphone. Being mobile friendly is no longer a luxury, it's a necessity. Unleash the power of mobile, and redefine your business success with our, bespoke mobile application development services. Ready to transform your vision into a compelling app? We're just a click away.",
                    "mobile-hero-title": "Imagination at your fingertips",
                    "mobile-hero-subtitle": "Your vision, our craftsmanship",
                    "mobile-hero-desc": "At Alluxi, we blend cutting-edge technology, innovative design, and strategic expertise to create mobile applications that are more than just functional - they're transformative. Improve your organization by captivating users and drive business growth.",
                    "nearshore-service": "Nearshore Development",
                    "nearshore-service-desc": "Achieve cost-effective and high-quality solutions for software development, design, maintenance, QA, testing, and support.",
                    "nearshore-title": "world-class nearshore software development services",
                    "nearshore-subtitle": "Beat Timezones.\n Escape Low-quality.\nCut costs with us.",
                    "nearshore-hero-title": "Your trusted partner for nearshore software solutions",
                    "nearshore-hero-subtitle": "Leverage our global talent to propel your business",
                    "nearshore-content": "Move faster than your competition by reducing geographical gaps and gain access to a talent pool of top-notch nearshore software development services. Our team of experienced developers is dedicated to creating tailored solutions that meet your unique business needs. We've empowered various industries - Finance, Healthcare, Education, and more – through our high-quality, cost-effective services.\n\nCollaborating closely with you, we ensure smooth project management, seamless communication, and delivery of results that exceed your expectations. With us, you will not be outsourcing your software development, you're gaining a reliable partner committed to your success.",
                    "nearshore-benefit-title": "Nearshore Development: Your Success, Our Goal",
                    "nearshore-benefit-subtitle": "Building smarter and cost-effective solutions together",
                    "nearshore-benefit1": "Boost savings with our cost-efficient nearshore development.",
                    "nearshore-benefit2": "Experience effortless communication with our multilingual team.",
                    "nearshore-benefit3": "Trust in the quality assured by our certified pros.",
                    "nearshore-benefit4": "Enjoy seamless coordination thanks to time-zone compatibility.",
                    "nearshore-benefit5": "Gain market insights from our cultural compatibility.",
                    "nearshore-roles-title": "Available roles",
                    "nearshore-roles-desc": "Discover the team that will bring your software visions to life. At Alluxi, we offer a variety of professional roles tailored to meet your unique needs. Our experts in nearshore development services are ready to tackle your challenges and deliver high-quality, innovative solutions.",
                    "ux/ui-title": "Unleashing impactful experiences with exceptional UI/UX design",
                    "ux/ui-subtitle": "Turning user journeys into a delight with intuitive design",
                    "ux/ui-desc-title": "Designing user-centric interfaces for better engagement",
                    "ux/ui-desc-subtitle": "Redefining digital experiences through innovative UI/UX solutions",
                    "ux/ui-desc": "At Alluxi, we believe in the power of design to enhance digital experiences. We offer UI/UX services that put users at the center of our design process. Our team of skilled UI/UX designers works closely with clients to understand their goals and target audience, creating intuitive, engaging, and visually compelling interfaces that meet user needs and drive business growth.",
                    "ux/ui-benefit-title": "Transform your digital presence with the power of UI/UX",
                    "ux/ui-benefit-subtitle": "Experience the difference that high-quality UI/UX can make",
                    "ux/ui-benefit1": "Boost user engagement and satisfaction",
                    "ux/ui-benefit2": "Improve usability and functionality",
                    "ux/ui-benefit3": "Drive higher conversion rates",
                    "ux/ui-benefit4": "Foster user loyalty and retention",
                    "ux/ui-benefit5": "Enhance overall user experience",
                    "ux/ui-benefit6": "Increase business growth and profitability",
                    cloud: "Cloud Services",
                    "cloud-title": "Navigating the cloud with precision and expertise",
                    "cloud-subtitle": "Empowering businesses through agile and scalable cloud solutions",
                    "cloud-desc-title": "Streamlining operations with secure and robust cloud services",
                    "cloud-desc-subtitle": "Experience the power of the cloud with Alluxi's tailored solutions",
                    "cloud-desc": "At Alluxi, we recognize the transformative potential of the cloud for businesses of all sizes. Our cloud services are designed to drive efficiency, agility, and scalability while ensuring high levels of security. \n\nWe partner with businesses to understand their unique needs and deliver customized cloud solutions that help them streamline operations, reduce costs, and foster innovation. Trust Alluxi to guide your cloud journey and unlock the full potential of your business.",
                    "cloud-benefit-title": "Realize the benefits of the cloud with Alluxi",
                    "cloud-benefit-subtitle": "Unleashing business growth and innovation through the cloud",
                    "cloud-benefit1": "Enhance operational efficiency and agility",
                    "cloud-benefit2": "Reduce infrastructure costs significantly",
                    "cloud-benefit3": "Drive innovation and scalability",
                    "cloud-benefit4": "Ensure high levels of data security",
                    "cloud-benefit5": "Improve business continuity and disaster recovery",
                    "cloud-benefit6": "Enable remote working capabilities",
                    "ai-title": "Harnessing artificial intelligence to drive business growth",
                    "ai-subtitle": "Empowering enterprises with intelligent and scalable AI solutions",
                    "ai-desc-title": "Leveraging AI to transform business operations",
                    "ai-desc-subtitle": "Building smarter businesses with customized AI solutions",
                    "ai-desc": "At Alluxi, we believe in the transformative power of AI for businesses. Our AI services aim to enhance business processes, improve decision-making, and unlock new avenues for growth. Our team of AI experts works closely with clients to understand their unique business context and goals, delivering customized AI solutions that drive efficiency, productivity, and competitive advantage. Trust Alluxi to unlock the potential of AI for your business.",
                    "ai-benefit-title": "Reap the benefits of AI with Alluxi",
                    "ai-benefit-subtitle": "Driving business innovation and growth with AI",
                    "ai-benefit1": "Streamline business processes",
                    "ai-benefit2": "Enhance decision-making capabilities",
                    "ai-benefit3": "Drive productivity and efficiency",
                    "ai-benefit4": "Improve customer experience and engagement",
                    "ai-benefit5": "Foster business innovation and growth",
                    "ai-benefit6": "Gain a competitive advantage",
                    team: "Team",
                    developers: "Developers",
                    designers: "Designers",
                    testers: "Testers",
                    "project-managers": "Project Managers",
                    "our-work": "Case studies",
                    "our-work-title": "Products making the difference",
                    "our-work-desc": "Empowering Businesses and Transforming Lives Through Innovative Solutions",
                    "see-our-services": "See our services in action",
                    "our-work-button": "View recent work",
                    methodologies: "METHODOLOGIES",
                    "how-we-work": "How we work",
                    "how-we-work-desc": "We will walk you through our proven and reliable methodology we have tested and polished over years of experience. The process begins by understanding your specific needs, followed by identifying the experts who best fit your requirements. We closely collaborate with you throughout the project development, ensuring open and constant communication for superior results. Your success is our priority, and with our approach that's professional yet approachable, we provide the best solutions tailored to your business goals.",
                    prototype: "PROTOTYPE",
                    "do-it-right": "Reduce costs",
                    "do-it-right-desc": "We aim to help you cut down both time and costs in your development journey. Creating a prototype not only enables you to visualize your final product in a tangible and early manner, but it also allows us to test and validate your ideas before investing in full product construction. \nThis initial prototyping phase is crucial to identify potential roadblocks or areas of improvement, saving you time and money on future modifications. During this step, we take into account your requirements, analyze the functionality, and focus our energy on ensuring the product design aligns with your business objectives.",
                    MVP: "MVP DEVELOPMENT",
                    "get-on-road": "Get on the road",
                    "get-on-road-desc": "You might be on the gateway to transforming your ideas into a new business. We will guide you through the initial steps to start working with us. From the get-go, you will receive dedicated and personalized support to help clarify your needs and goals. Together, we will uncover the best way to leverage nearshore talent in Latin America to drive your business development. Whether your project is just in the conceptual stage or you need support for an initiative already underway, we are here to help you kick-start your path to success.",
                    support: "SUPPORT",
                    "we-have-back": "We have your back",
                    "we-have-back-desc": "As part of the Alluxi team, we consider it crucial to be completely committed to our clients, this is why we offer technical support in the shortest possible periods through the process of working together. Listening and learning from our clients we invest time in growing a relationship that will directly support the result of the project.",
                    "why-choose-us": "WHY CHOOSE US",
                    "our-customers-love": "Our customers love what we do",
                    "we-use-modern-tool": "We use the most modern & effective tools.",
                    "we-improve": "We constantly improve our work methodologies to provide you with the best service.",
                    "we-look": "We always look for the most adequate solution at affordable prices.",
                    "swiper-zubillaga": "“Always understanding our requirements to the detail, designing and developing high-quality software.”",
                    "swiper-Dana": "“”",
                    awards: "Awards and recognitions",
                    "blog-title": "Everything you need to know and more",
                    "blog-desc": "We give actionable tips, strategies, and techniques to grow your business.",
                    "see-all-post": "See all our posts",
                    "see-more": "See more",
                    tools: "TOOLS",
                    "our-teck-stack": "Our tech stack",
                    "our-teck-desc": "We have steeped in experience across diverse technology stacks in software and mobile web development. This expertise, paired with our deep connection with your business, enables us to deliver bespoke solutions that empower growth.",
                    "and-more": "And more",
                    "we-have-all": "We have it all",
                    "we-have-all-desc": "Standing at the intersection of technology, and innovation. With a rich history in Artificial Intelligence, UX/UI services, Software Development, and Analytics, we blend our experience with cutting-edge approaches to craft outstanding solutions. Whether it's intuitive designs, smart software, or insightful analytics, our mission is to bring your vision to life.",
                    "user-experience": "User Experience",
                    "user-experience-desc": "Strategy, technology and creativity are our tools to exceed our client's expectations.",
                    "cloud-services": "Cloud Services",
                    "cloud-services-desc": "Reliable, secure, and fast options for cloud development and DevOps to save time, money and recover peace of mind.",
                    "ai-integration": "AI Integration",
                    "ai-integration-desc": "Supercharge your existing platform with powerful AI integration for enhanced performance and advanced capabilities.",
                    analytics: "Analytics",
                    "analytics-desc": "We offer enterprise-level business intelligence and analytics solutions to help clients analyze their data.",
                    "products-difference": "Products making the difference",
                    "analytics-sub": "Harnessing the power of data",
                    "analytics-title": "From user insights to advanced data solutions",
                    "analytics-content": "Beyond mere user insights, our approach encompasses data analytics, robust data science methodologies, and reliable data engineering. Our commitment: Turning raw data into actionable insights for your business.",
                    "analytics-sub-2": "Tools of the Trade",
                    "analytics-title-2": "Empowering Business with Cutting-Edge Techniques",
                    "analytics-content-2": "Our suite of advanced tools and methodologies in data analytics, science, and engineering ensures precision and scalability. Employing state-of-the-art techniques, we navigate the data landscape, driving meaningful outcomes for your brand.",
                    "analytics-benefits-subtitle": "Advantages of a Data-Driven Approach",
                    "analytics-benefits-title": "Integral benefits of data services",
                    "analytics-benefit1": "Boost business intelligence.",
                    "analytics-benefit2": "Optimize operational efficiency.",
                    "analytics-benefit3": "Predict market trends.",
                    "analytics-benefit4": "Enhance customer personalization.",
                    "analytics-benefit5": "Drive innovation with insights.",
                    plataform: "Plataform",
                    technologies: "Technologies",
                    "react-software-development": "React Software Development",
                    "react-title": "Revolutionize your business with \n custom react software development solutions",
                    "react-description": "Harness the power of React to build scalable and intuitive applications that delight users and drive growth.",
                    "react-service-title": "React Software Development Services",
                    "react-service-description": "From initial ideation to deployment, our expert React developers will guide you through each step, ensuring top-notch quality and performance.",
                    "react-service-name1": "React web development",
                    "react-service-description1": "Transform your digital experience with agile and responsive React web applications.",
                    "react-service-name2": "React Native development",
                    "react-service-description2": "Expand your reach with cross-platform mobile applications built with React Native.",
                    "react-service-name3": "React consulting",
                    "react-service-description3": "Gain strategic insights and technical expertise from our experienced React consultants.",
                    "react-what-we-do-title": "Why choose us for React Development",
                    "react-what-we-do-description": "Our approach blends cutting-edge technology with a deep understanding of your business needs. We're committed to delivering robust React solutions that offer excellent user experience and ROI.",
                    "python-software-development": "Python Software Development",
                    "python-title": "Unlock endless possibilities with \nPython software development",
                    "python-description": "Leverage Python's versatility to build powerful applications that solve real-world problems.",
                    "python-service-title": "Python Software Development Services",
                    "python-service-description": "Whether it's web development, data analytics, or AI, our Python experts are up to the task.",
                    "python-service-name1": "Web Development",
                    "python-service-description1": "Build highly secure and scalable web applications tailored to your business requirements.",
                    "python-service-name2": "Machine Learning",
                    "python-service-description2": "Utilize Python's advanced libraries for machine learning to gain actionable insights from your data.",
                    "python-service-name3": "Automation",
                    "python-service-description3": "Automate your workflow and processes with our customized Python solutions.",
                    "python-what-we-do-title": "Our Python expertise",
                    "python-what-we-do-description": "We offer a suite of Python development services, prioritizing code quality, scalability, and timely delivery.",

                    "aws-title": "Build and scale with AWS cloud solutions",
                    "aws-description": "Leverage the vast capabilities of AWS to build, deploy, and scale applications efficiently.",
                    "aws-service-title": "AWS cloud services",
                    "aws-service-description": "Take advantage of our AWS expertise to architect, migrate, and manage complex cloud solutions.",
                    "aws-service-name1": "Cloud Migration",
                    "aws-service-description1": "Move your applications and data to the cloud seamlessly with our AWS migration services.",
                    "aws-service-name2": "DevOps Solutions",
                    "aws-service-description2": "Automate your CI/CD pipeline and improve delivery with AWS DevOps tools.",
                    "aws-service-name3": "Managed Services",
                    "aws-service-description3": "Let us handle the complexities of AWS management so you can focus on your core business.",
                    "aws-what-we-do-title": "Why choose us for AWS Services",
                    "aws-what-we-do-description": "Our AWS-certified professionals ensure that you get the most out of AWS features, maximizing performance while minimizing costs.",

                    "js-title": "JavaScript software development that transforms UX",
                    "js-description": "Create interactive and dynamic web applications using the power of modern JavaScript frameworks.",
                    "js-service-title": "JavaScript Software Development Services",
                    "js-service-description": "Our JavaScript experts are skilled in all major JS frameworks and libraries, offering you a wide array of choices.",
                    "js-service-name1": "Front-end Development",
                    "js-service-description1": "Deliver visually stunning and highly responsive user interfaces.",
                    "js-service-name2": "Back-end Development",
                    "js-service-description2": "Build reliable and scalable server-side applications using Node.js.",
                    "js-service-name3": "SPA & PWA Development",
                    "js-service-description3": "Craft Single Page Applications and Progressive Web Apps that offer native-like experiences.",
                    "js-what-we-do-title": "Why choose us for JavaScript Development",
                    "js-what-we-do-description": "Our developers stay up-to-date with the latest JavaScript technologies, ensuring that your application is both modern and future-proof.",

                    "django-title": "Fast and Scalable Web Development with Django",
                    "django-description": "Opt for Django to build secure and maintainable web applications that can scale effortlessly.",
                    "django-service-title": "Django development services",
                    "django-service-description": "From start-ups to enterprises, our Django experts can build solutions tailored to meet your business needs.",
                    "django-service-name1": "Custom web Applications",
                    "django-service-description1": "Get customized web solutions built on Django's powerful backend capabilities.",
                    "django-service-name2": "API Development",
                    "django-service-description2": "Develop robust APIs with Django Rest Framework for your mobile and web applications.",
                    "django-service-name3": "E-commerce Solutions",
                    "django-service-description3": "Launch your online store quickly and securely with our Django e-commerce solutions.",
                    "django-what-we-do-title": "Why choose us for Django Development",
                    "django-what-we-do-description": "Our Django developers are well-versed in the best practices and methodologies, ensuring rapid development without compromising quality.",



                    "react-native-title": "Elevate your mobile experience with React Native",
                    "react-native-description": "Harness the power of React Native to build scalable and high-performing mobile applications.",
                    "react-native-service-title": "React Native development services",
                    "react-native-service-description": "Unlock exceptional mobile experiences with our React Native development services.",
                    "react-native-service-name1": "React Native consultation",
                    "react-native-service-description1": "Get expert guidance on migrating to or starting a new project in React Native.",
                    "react-native-service-name2": "React Native UI/UX Design",
                    "react-native-service-description2": "Achieve a responsive and visually stunning user interface with our design expertise.",
                    "react-native-service-name3": "React Native maintenance",
                    "react-native-service-description3": "Ensure your app stays up-to-date and bug-free with our ongoing maintenance services.",
                    "react-native-what-we-do-title": "What we do in React Native",
                    "react-native-what-we-do-description": "From the initial consultation to post-launch maintenance, we cover all aspects of React Native development. Our team focuses on writing clean code, ensuring scalability, and delivering unparalleled user experiences.",

                    "ionic-title": "Build versatile apps with Ionic",
                    "ionic-description": "Utilize Ionic for rapid development and cross-platform compatibility.",
                    "ionic-service-title": "Ionic Software Development Services",
                    "ionic-service-description": "Leverage Ionic's platform-agnostic technology for versatile and cost-effective mobile solutions.",
                    "ionic-service-name1": "Ionic App Development",
                    "ionic-service-description1": "Build robust apps that work seamlessly across multiple platforms.",
                    "ionic-service-name2": "Ionic Integration",
                    "ionic-service-description2": "Easily integrate with various back-end and third-party services.",
                    "ionic-service-name3": "Ionic Performance Tuning",
                    "ionic-service-description3": "Optimize your app's performance for a smoother user experience.",
                    "ionic-what-we-do-title": "What we do in Ionic",
                    "ionic-what-we-do-description": "From prototype to production, we provide a full suite of Ionic development services that bring your mobile vision to life.",

                    "postgres-title": "Power your data with PostgreSQL",
                    "postgres-description": "Experience robust, scalable database solutions with PostgreSQL.",
                    "postgres-service-title": "PostgreSQL Development Services",
                    "postgres-service-description": "Create, manage, and scale your database with our specialized PostgreSQL services.",
                    "postgres-service-name1": "Database Design",
                    "postgres-service-description1": "Design a well-structured, efficient database tailored to your business needs.",
                    "postgres-service-name2": "Data Migration",
                    "postgres-service-description2": "Safely migrate your data to PostgreSQL without any downtime or loss.",
                    "postgres-service-name3": "Performance Optimization",
                    "postgres-service-description3": "Maximize your database's performance with advanced tuning.",
                    "postgres-what-we-do-title": "What we do in PostgreSQL",
                    "postgres-what-we-do-description": "Our PostgreSQL services encompass everything from initial setup to maintenance, ensuring your data is always secure and accessible.",

                    "vue-title": "Revolutionize your web applications with Vue.js",
                    "vue-description": "Implement Vue.js for a lightweight, yet powerful, front-end user experience.",
                    "vue-service-title": "Vue Development Services",
                    "vue-service-description": "Redefine user interaction and UI with our expert Vue.js development services.",
                    "vue-service-name1": "Vue.js Custom Development",
                    "vue-service-description1": "Custom solutions that focus on optimizing user engagement.",
                    "vue-service-name2": "Vue.js SPA Development",
                    "vue-service-description2": "Create single-page applications that deliver fast and fluid user experiences.",
                    "vue-service-name3": "Vue.js Support and Maintenance",
                    "vue-service-description3": "Regular updates and quick fixes to keep your Vue.js application running smoothly.",
                    "vue-what-we-do-title": "What we do in Vue",
                    "vue-what-we-do-description": "Whether it's custom development or ongoing support, we offer Vue.js services that align with your business goals.",
                    "industry-title": "Industries We Excel In",
                    "health-care-description": "Streamline medical processes and improve patient care with our cutting-edge healthcare solutions.",
                    "finance-description": "Enhance your financial operations and secure user transactions with our robust FinTech solutions.",
                    "education-description": "Empower learners and educators alike through our intuitive and dynamic educational platforms.",
                    challenges: "Challenges",
                    "problems-solve": "Problems to solve",
                    solutions: "Solutions",
                    "final-results": "Final results",
                    about: "About us",
                    "about-title": "We are here to make your business successful",
                    "about-us-title": "We take your ideas to real and successful products",
                    "about-desc": "We deliver on time and budget that exceed expectations.",
                    "about-us-desc": "We do not want to do only what you ask us to, we want to go further, understanding and getting involved in your development process.",
                    "about-question": "What does this mean to you?",
                    "b-ideas": "Bring your ideas to life with tailored development services.",
                    "b-cost": "Cost-effective nearshore solutions with a local team.",
                    "b-flexibility": "Flexibility to meet your needs and exceed expectations.",
                    "b-communication": "Open communication for a collaborative development process.",
                    "b-innovative": "Innovative solutions to make your product stand out.",
                    "b-satisfaction": "Your satisfaction is our top priority.",
                    commitment: "Commitment",
                    "our-mission": "Our mission",
                    "our-mission-desc": "We do not want to do only what you ask us to, we want to go further, understanding and getting involved in your development process.\n\nWe want to know the mission behind your business, understand why it goes to that place, and help you get there.\n\nBecoming a partner and technical ally to our clients so we focus on developing high-quality technological solutions, while they can focus on the core of their business. This approach allows us to build software with a future in mind and build relationships with our customers that last for many years.\n\nWe believe success is not reached at the end of a project, but instead, it is repeated constantly throughout the process of software development.",
                    "our-values": "Our values",
                    passion: "Passion",
                    "passion-desc": "We love what we do and believe in continuous improvement.",
                    teamwork: "Teamwork",
                    "teamwork-desc": "We put together our skills to achieve amazing results, always over an environment full of collaboration, acknowledgment and respect.",
                    responsibility: "Responsibility",
                    "responsibility-desc": "We always deliver the objectives that were set from the beginning and accept the challenges that these entail.",
                    accountability: "Accountability",
                    "accountability-desc": "We are proud of the work we do. Our reputation is our introduction letter.",
                    ally: "We're an ally",
                    "ally-desc": "We are not just another software vendor. We want to be a true ally of your project and see it succeed.",
                    careers: "Careers",
                    "careers-title": "Help us deliver great products",
                    "career-subtitle": "Shape the Future with Your Talent",
                    "careers-desc": "Discover the opportunity to be part of our leading software agency, where we embrace a remote-first work environment and cater to the exceptional talent in LATAM. Experience the freedom of crafting your work-life balance while benefiting from a supportive company that values your well-being and growth. With us, you'll find an ideal space to thrive professionally and personally. Join our dynamic family today and embark on an extraordinary journey with us!",
                    remote: "Remote",
                    benefits: "Benefits",
                    "work-with-us": "Work with us",
                    "remote-first": "Remote first",
                    "flexible-time": "Flexible working time",
                    "self-improvement": "Self improvement budget",
                    "vacation-days": "10 Vacations days since first year",
                    "open-roles": "Open roles",
                    engineering: "Engineering",
                    "full-stack-eng": "Full Stack Software Engineer",
                    "backend-eng": "Backend Engineer",
                    "python-eng": "Senior Python Engineer",
                    design: "Design / UX Jobs",
                    "ux-designer": "UI/UX Designer",
                    "product-designer": "Product Designer",
                    sales: "Sales job",
                    "sales-develop": "Sales Development Representative",
                    management: "Operation Jobs / Management",
                    programming: "Programming Jobs",
                    "full-stack-python-dev": "Full-Stack Python/React Developer",
                    "full-stack-php-dev": "Full-Stack Full-Stack PHP/Laravel Developer",
                    industries: "Industries",
                    "how-can-we-help": "How can we help",
                    value: "Value",
                    "health-care": "Healthcare",
                    "health-care-desc": "In an industry where precision and efficiency are crucial, many healthcare organizations struggle to implement digital transformations in-house. At Alluxi, we bridge this gap by offering bespoke software solutions, tailored to the medical industry needs, helping your healthcare business transition seamlessly from traditional processes to a digital platform equipped with the latest technology. \n\nOur aim? To improve care delivery, improve the patient experience in times of need, and take your business (and your patient's health) to the next level.",
                    "health-care-desc_title": "Digital Transformation Services for Healthcare",
                    "health-care-desc_subtitle": "We leverage technological innovation for enhanced healthcare delivery",
                    "health-care-desc_text": "We bring our deep experience and expertise to the table, helping healthcare professionals develop and integrate result-driven digital solutions. Our commitment goes beyond providing services; we partner with healthcare organizations eager to challenge the status quo and optimize their operations through strategic digital transformations.",
                    "health-care-help-title": "Revolutionize healthcare through tailored digital solutions",
                    "health-care-help-desc": "We bring together innovation, expertise, and understanding to design and develop impactful solutions. Our meticulous approach ensures a successful, regulation-compliant launch that integrates smoothly into existing systems. But we don't stop at the launch – our ongoing support services provide software updates, troubleshooting, and user training to ensure our solutions continually meet user needs. As the landscape of your industry evolves, we adapt our support to ensure the enduring success of our products.",
                    "health-care-design-title": "Design and Develop",
                    "health-care-design-desc": "We create impactful healthcare solutions, blending innovation and technical expertise. Our focus lies in developing patient-centric applications and efficient healthcare data management systems. Our understanding of healthcare's unique challenges enables us to design with safety, privacy, and accessibility at the forefront. We're committed to improving healthcare experiences and outcomes through effective design and development.",
                    "health-care-launch-title": "Launch",
                    "health-care-launch-desc": "Our meticulous approach ensures a successful launch of your healthcare product or service. We guarantee compliance with regulations, smooth integration with existing systems, and a positive initial impact. Our strategy minimizes disruption while maximizing benefits, preparing your team and users for a seamless transition.",
                    "health-care-support-title": "Support",
                    "health-care-support-desc": "Our support doesn't end at launch. We offer continuous assistance, including software updates, troubleshooting, and user training. We monitor our solutions' performance, ensuring they consistently meet user needs. As the healthcare landscape changes, we adapt our support services to match, ensuring the long-term success of our products.",
                    "health-care-benefit-title": "Embrace the Future of Healthcare with Alluxi",
                    "health-care-benefit-desc": "The benefits go beyond digitization. Our tailored solutions enable you to simplify complex processes, improve service delivery, and make data-driven decisions, all while maintaining regulatory compliance. Partner with us and redefine the boundaries of what's possible in healthcare.",
                    "health-care-benefit1": "<p><strong>Custom Healthcare Platforms:</strong> Our team designs user-centric e-commerce platforms that facilitate seamless interactions between buyers and sellers in the healthcare industry.</p>",
                    "health-care-benefit2": "<p><strong>Personalized Digital Health Services:</strong> We help you distribute health services and information digitally, creating personalized strategies to meet your unique needs efficiently.</p>",
                    "health-care-benefit3": "<p><strong>Enhanced Patient Experiences:</strong> We build digital platforms that foster strong relationships between patients and medical professionals, putting the patient at the center of every interaction.</p>",
                    "health-care-benefit4": "<p><strong>Integrated Health Solutions:</strong> We offer a fully-integrated digital solution to enhance telemedicine services, making it easily accessible for both patients and medical professionals.</p>",
                    "health-care-benefit5": "<p><strong>Data-Driven Decision Making:</strong> With our custom solutions, you can leverage healthcare data analytics for better decision making, improving outcomes and operational efficiency.</p>",
                    "health-care-benefit6": "<p><strong>Regulatory Compliance:</strong> We ensure that all our software solutions comply with health industry regulations, providing peace of mind as you navigate your digital transformation journey.</p>",
                    finance: "Finance",
                    "finance-desc": "In the dynamic landscape of financial services, digital transformation is key to enhancing customer experiences and driving growth. From attracting new clients to simplifying banking operations and harnessing emerging technologies, financial institutions must blend strategic product design with robust technical capabilities to provide seamless banking experiences.",
                    "finance-desc_title": "Navigating digital transformation with Alluxi",
                    "finance-desc_subtitle": "Leverage technology to redefine financial experiences",
                    "finance-desc_text": "At Alluxi, we foster seamless collaboration with every client to identify new opportunities and design strategic roadmaps. Our expertise in the financial software development arena enables us to revolutionize the way businesses operate in the financial sector.",
                    "finance-help-title": "Revolutionizing the financial services industry through digital transformation",
                    "finance-help-desc": "We craft innovative financial solutions, merging creativity and technical mastery. Our team specializes in user-friendly applications and robust financial data management systems. We navigate the complexities of finance, designing with security, privacy, and compliance in mind. We're dedicated to enhancing financial experiences and outcomes through our strategic design and development.",
                    "finance-design-title": "Design and Develop",
                    "finance-design-desc": "We craft innovative financial solutions, merging creativity and technical mastery. Our team specializes in user-friendly applications and robust financial data management systems. We navigate the complexities of finance, designing with security, privacy, and compliance in mind. We're dedicated to enhancing financial experiences and outcomes through our strategic design and development.",
                    "finance-launch-title": "Launch ",
                    "finance-launch-desc": "Our approach ensures a successful launch of your financial product or service. We ensure regulatory compliance, seamless integration with existing systems, and a strong initial impact. We prepare your team and users for a smooth transition, minimizing disruption and maximizing benefits.",
                    "finance-support-title": "Support",
                    "finance-support-desc": "Post-launch, we provide continuous support, managing software updates, troubleshooting, and user training. We monitor solution performance, ensuring constant alignment with user needs. As the financial landscape evolves, our support adapts to ensure the enduring success of our solutions.",
                    "finance-benefit-title": "Unleash the market potential with custom financial solutions",
                    "finance-benefit-desc": "With Alluxi, embrace the benefits of digital transformation in the financial sector, from enhanced customer experiences to streamlined operations. Our digital solutions not only optimize business operations but also ensure compliance, enhance security, and prepare you for scalable growth.",
                    "finance-benefit1": "<p><strong>Consumer Fintech Software:</strong> Our digital solutions offer consumers enhanced visibility and control over their financial operations. We harness the power of Fintech to streamline money services and deliver superior digital experiences.</p>",
                    "finance-benefit2": "<p><strong>Digital Banking Solutions:</strong> As digital banking grows in popularity, customers expect an online experience that matches or surpasses their in-branch experience. Alluxi crafts secure digital solutions for traditional banks to meet and exceed these expectations.</p>",
                    "finance-benefit3": "<p><strong>Portfolio Investment Tools:</strong> We develop advanced digital tools for portfolio management, enabling the financial industry to leverage detailed analytics and data for improved decision-making and accurate forecasting.</p>",
                    "finance-benefit4": "<p><strong>Regulatory Compliance:</strong> Our solutions ensure that your institution remains compliant with financial regulations and standards while driving digital innovation.</p>",
                    "finance-benefit5": "<p><strong>Security:</strong> We prioritize security in all our solutions, ensuring the protection of sensitive financial information and building trust with your customers.</p>",
                    "finance-benefit6": "<p><strong>Scalability:</strong> Our financial software solutions are designed to accommodate growth and adapt to future technological advancements.</p>",
                    education: "Education",
                    "education-desc": "In the rapidly evolving world of education, both educators and learners must continuously adapt to emerging online platforms and methodologies. Customizable, scalable, and effective educational software is increasingly becoming the cornerstone of successful academic institutions. At Alluxi, we harness modern technology to build such solutions, enhancing education delivery and providing a platform for learning, anytime, anywhere.",
                    "education-desc_title": "Alluxi's digital transformation services for the education sector",
                    "education-desc_subtitle": "Integrating technology and pedagogy for enriched learning experiences",
                    "education-desc_text": "At Alluxi, we leverage our extensive expertise to deliver top-tier development solutions for the education sector. By merging cutting-edge technology with best academic practices, we create powerful digital solutions that boost performance, drive growth, and improve outcomes. We're committed to forming solid digital foundations for your institution, ensuring smooth transitions into this new educational landscape.",
                    "education-help-title": "Empowering Learning",
                    "education-help-desc": "We merge innovation, expertise, and insight to develop transformative educational solutions. Our detailed launch strategy ensures successful, standards-aligned implementation. Post-launch, we offer continuous support including updates, troubleshooting, and user training, adapting as the educational landscape evolves, ensuring enduring success of our products.",
                    "education-design-title": "Design and Develop",
                    "education-design-desc": "We create influential educational solutions, combining creativity and technical proficiency. Our team excels at developing engaging learning applications and efficient education data management systems. Understanding the intricacies of education, we design for accessibility, engagement, and effectiveness. Our commitment is to improve educational experiences and outcomes through our insightful design and development.",
                    "education-launch-title": "Launch ",
                    "education-launch-desc": "Our meticulous approach ensures a successful launch of your educational product or service. We guarantee alignment with educational standards, seamless integration into existing systems, and a promising initial impact. Our strategy minimizes disruption while maximizing learning opportunities, readying your team and users for a smooth transition.",
                    "education-support-title": "Support",
                    "education-support-desc": "After launch, we provide ongoing support, overseeing software updates, troubleshooting, and user training. We monitor solution performance, ensuring consistent alignment with user needs. As the educational landscape changes, we adapt our support to ensure the long-term success of our solutions.",
                    "education-benefit-title": "Empower learning and teaching with Alluxi",
                    "education-benefit-desc": "The benefits of digital transformation in the education sector are multi-dimensional. Our solutions not only simplify teaching and learning processes but also enable data-driven decision-making, ensure regulatory compliance, and uphold privacy and security. Partner with us to redefine the educational journey.",
                    "education-benefit1": "<p><strong>Custom E-Learning Platforms:</strong> We design and develop bespoke e-learning platforms that support your academic model and enhance the digital transition from traditional education methods.</p>",
                    "education-benefit2": "<p><strong>Learning Management Software:</strong> Our solutions help educators manage e-learning effectively, keeping students engaged with user-friendly, interactive platforms.</p>",
                    "education-benefit3": "<p><strong>Personalized Digital Tools for Students:</strong> We recognize that every student has unique learning needs. Our tailored digital tools give students the flexibility to learn at their own pace and improve communication with educators.</p>",
                    "education-benefit4": "<p><strong>Educational Data Analytics:</strong> Harness the power of data to improve teaching and learning outcomes. Our solutions include advanced analytics dashboards for insights and visualizing student progress.</p>",
                    "education-benefit5": "<p><strong>Security and Compliance:</strong> We ensure that all our software solutions comply with education industry regulations and maintain the highest standards of data privacy and security.</p>",
                    "education-benefit6": "<p><strong>Scalability:</strong> Our solutions are designed to grow with your institution, accommodating increasing numbers of users and adapting to evolving educational trends.</p>",
                    by: "BY",
                    "related-posts": "Related posts",
                    "discover-more": "Discover more",
                    "get-in-touch": "Get in touch",
                    contact: "Contact Us",
                    "tell-us-more": "Tell us more about your product",
                    "contact-desc": "We want to know more about your project. Leave us your information and share what you're building, and we will contact you shortly.",
                    name: "Name",
                    "enter-name": "Enter name",
                    email: "Email",
                    "enter-email": "Enter email",
                    "invalid-email": "Invalid email",
                    "invalid-message": "Write a message with at least 10 characters",
                    "project-detail": "Project Details",
                    "enter-project-detail": "Enter Project Details",
                    message: "Message",
                    "enter-message": "Enter message",
                    "which-services": "Which services are you interested in?",
                    "web-develop": "Web Development",
                    "mobile-develop": "Mobile Development",
                    send: "Send",
                    "thanks-contacting-us": "Thank you for contacting us!\nWe have received your message, we will get back to you as soon as possible.",
                    "error-contact-us": "Oops! Seems like there was an error in sending the email.\nOur engineers will review this shortly.",
                    "error-recaptcha": "Please accept captcha",
                    ready: "Ready for your next project?",
                    "ready-desc": "Tell us about your ideas so we can create a successful product together!",
                    "get-tips": "Get tips, case studies, and stay up to date on Alluxi.",
                    "email-adress": "Email adress",
                    sending: "Sending...",
                    "thanks-sub": "Thanks for subscribing!",
                    "about-footer": "About",
                    "privacy-policy": "Privacy Policy",
                    "pp-paragraph-1": `ALLUXI S.A DE C.V., (hereinafter "ALLUXI") is a company incorporated under the Laws of the United Mexican States, with a registered office located at América 327, Colonia Centro, C.P. 64000, Monterrey, Nuevo León, and we are aware that you as a visitor to our offices and website, consumer or potential consumer of our products and/or services, have the right to know what information we collect from you and our practices in relation to such information.`,
                    "pp-paragraph-2": `The terms and conditions contained herein apply to information collected on behalf of and by ALLUXI or any of its affiliates or subsidiaries, by any means, including through the ALLUXI website or any other site operated by ALLUXI.`,
                    "pp-paragraph-3": `The personal data that ALLUXI may collect directly or indirectly consists of the following: your full name, address, home, work, and mobile telephone numbers, e-mail address and occupation, as well as financial or property data. We undertake that all data obtained will be treated under the strictest security measures to guarantee its confidentiality.`,
                    "pp-paragraph-4": `ALLUXI informs that all contracts for the provision of services with third parties involving the processing of your personal information on behalf of ALLUXI shall include a clause guaranteeing that they grant the level of protection of personal data. In any case, all handling of personal data shall be carried out in compliance with the Federal Law for the Protection of Personal Data in Possession of Private Parties (hereinafter the "Law") and its Regulations.`,
                    "pp-paragraph-5": `The purpose for which we collect your data is to provide services and products requested, to inform you about new products, services or changes in them, promotional messages and to evaluate the quality of the service we provide.`,
                    "pp-paragraph-6": `The information you provide must be truthful and complete. Therefore, you are responsible for the accuracy of the data provided and ALLUXI shall not be held liable in this respect under any circumstances.`,
                    "pp-paragraph-7": `In addition, we will use your personal information for the following secondary purposes, that and facilitate us to provide you with a better service: we may notify you of promotions, offers and services via email, this type of advertising is done through notices and promotional email messages, which will only be sent to you and to those contacts registered for this purpose, this indication may be modified at any time. In the e-mails sent, we may occasionally include offers from third party business partners.`,
                    "pp-paragraph-8": `You may also exercise your ARCO rights (access, rectification, cancellation and opposition) regarding the processing of the personal data provided, as well as revoke the consent granted in this document, for which you should contact us by email at hola@alluxi.com; the procedure and requirements that your request must contain in accordance with the provisions of the Law and its Regulations, are as follows:`,
                    "pp-paragraph-9": `(1) Name and address; if the address is not included, the application will not be considered to have been received.
                    (2) The document that accredits your identity or the personality of your representative (copy of valid official identification). The representative must accredit the identity of the owner, the identity of the representative, and his/her powers of representation by means of a public instrument or power of attorney signed before two witnesses, or a declaration in personal appearance of the owner;
                    (3) A clear and precise description of the personal data to which he/she wishes to access, rectify, cancel or oppose.
                    (4) Description of other elements that facilitate the location of your personal data (website, branch office, etc.).`,
                    "pp-paragraph-10": `The documents must be scanned and attached to the email to verify the veracity of the documents.`,
                    "pp-paragraph-11": `To find out about the procedure, requirements and deadlines for exercising your rights, please contact us at hola@alluxi.com.`,
                    "pp-paragraph-12": `We inform you that on our Internet pages we use cookies, web beacons and other technologies through which it is possible to monitor your behaviour as an Internet user, to provide you with a better service and user experience when browsing our website, as well as to offer you new products and services based on your preferences. The personal data we obtain from these insights technologies are the following: browsing hours, time spent browsing our website, sections viewed, and websites accessed prior to our website.`,
                    "pp-paragraph-13": `In the case of the use of cookies, the "help" button on the toolbar of most browsers will tell you how to avoid accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable all cookies.`,
                    "pp-paragraph-14": `ALLUXI has adopted the personal data protection security levels legally required by the Law and its Regulations, under the principles of legality, quality, consent, information, purpose, loyalty, proportionality and responsibility. All this under strict rules of security, confidentiality and consistency of information.`,
                    "pp-paragraph-15": `We inform you that your personal data will be safeguarded under strict administrative, technical and physical security measures which have been implemented in order to protect your personal data against damage, loss, alteration, destruction or unauthorized use or access or processing.`,
                    "pp-paragraph-16": `The ALLUXI website connects to certain links to other websites belonging to third parties over which ALLUXI has no control. In these cases ALLUXI assumes no responsibility or liability for the privacy policies of the information contained on those sites.`,
                    "pp-paragraph-17": `ALLUXI reserves the right to make changes or updates to this privacy notice at any time, in order to comply with new legislation or jurisprudence, internal policies, new requirements for the provision or offering of our services and market practices, any changes to the Privacy Notice will be available through our website; section "privacy notice".`,
                    "pp-paragraph-18": `By browsing any of ALLUXI's websites, you signify that you have read, understood and agree to the above terms.`,
                    "pp-paragraph-19": `This Privacy Notice was amended on 20 October 2020.`,
                    follow: "Follow us",
                    "page-not-found": "Ooops! Page not found",
                    "page-not-found-desc": "The page you are looking for doesn't exist or has been moved.",
                    "take-me-home": "Take me home",
                    "right-reserved": "All rights reserved.",
                    FAQs: "FAQs",
                    "faqs-title": "Frequenly Ask Questions",
                    "type-dev-offer": "What types of software development services does Alluxi offer?",
                    "type-dev-offer-answer": `We a wide range of software development services, including web and mobile app development, custom software development, software testing and quality assurance, and software consulting and support.`,
                    "how-long": "How long does it typically take to complete a software development project?",
                    "how-long-answer": `The timeline for completing a software development project varies depending on the complexity of the project and the client's specific requirements. We work closely with each client to establish realistic timelines and milestones, and we use agile project management methodologies to ensure that projects stay on track. We're more than happy to provide a cost-free timeline specific to your needs.`,
                    "manage-communication": "What is your process for project management and communication with clients?",
                    "manage-communication-answer": `Alluxi uses an agile project management approach, which emphasizes collaboration, flexibility, and iterative development. We use project management tools like Jira and Asana to track progress and communicate with clients regularly via email, phone, and video conferencing.`,
                    "past-example": "Can you provide examples of similar projects Alluxi has worked on in the past?",
                    "past-example-answer": `Yes, we have worked on a wide range of software development projects for clients in various industries, including healthcare, finance, and e-commerce. We can provide case studies and references upon request or you can visit our Case studies section on the navbar.`,
                    costs: "What is Alluxi's pricing model and how are costs determined for a project?",
                    "costs-answer": `Our pricing model is based on the scope and complexity of the project, as well as the level of expertise required from our development team. We provide transparent and detailed time and budget estimates based on a thorough assessment of the client's needs and requirements.`,
                    testing: "What is your approach to testing and quality assurance during the software development process?",
                    "testing-answer": `Quality assurance is an essential part of our software development process. We use a range of testing methodologies, including unit testing, integration testing, and acceptance testing, to ensure that our software is reliable, functional, and user-friendly. We also provide ongoing maintenance and support to ensure that our client's software continues to meet their needs over time.`,
                    "garre-challenge": "We are always on the lookout for ways to help our clients succeed. Recently, we had the opportunity to work with a client in the airline industry who wanted to improve their online ticket sales. We were able to provide them with a custom web application that made it easy for customers to search for and purchase airline tickets.",
                    "garre-solution": "The application we created for our client was user-friendly and intuitive, allowing customers to quickly find the flights they were looking for. The search functionality was robust, allowing users to filter by destination, departure date, number of passengers, and other criteria. The results page was organized and easy to navigate, making it simple for users to compare different flight options and choose the one that best suited their needs.\nOnce a user had selected a flight, they were able to complete the purchase process quickly and securely. The application accepted a variety of payment options, including credit cards, debit cards, and PayPal. We also implemented strong security measures to protect sensitive information and prevent fraud.",
                    "garre-outcome": `<p>One of the key features of the application was its ability to display <strong>real-time flight information</strong>. This meant that users could see up-to-date information about <strong>flight times, delays, and cancellations</strong>, allowing them to make informed decisions about their travel plans.</p>
                    <p>Our <strong>client was extremely happy</strong> with the web application we developed for them. In the first month after launching the app, they experienced a significant <strong>increase in online ticket sales</strong>. The application helped them reach a wider audience and provided a convenient and user-friendly way for customers to purchase tickets.</p>
                    <p>Overall, we were able to deliver a <strong>high-quality product</strong> that met our client's needs and exceeded their expectations. We are confident that our web application will continue to help our client grow their business and improve their online presence.</p>`,
                    "nativis-challenge": "Nativis Projex is a platform to buy and rent eco friendly and sustainable homes, with the objective of turning the earth into a greener planet.",
                    "nativis-solution": "Nativis requested our services to build the Nativix Projex mobile app, a unique app for host management oriented towards hosting with special needs. The mobile app should be able to provide rental services as well as facilitate the purchase of real estate. It was very important for our client that the ecological spirit of this endeavour was highlighted. Favoring communication through social interactions between users.",
                    "nativis-outcome": `Alluxi suggested a <strong>Hybrid mobile app</strong> as it would allow us to quickly launch for  iOS and Android users. Additionally, we provided an admin tool which allows the client to monitor and control all operations that happen on the app. Users are currently able to <strong>post their properties</strong> on the app, as well as <strong>find properties for rent</strong>. The platform supports various roles as well as the interaction of ideas through <strong>social posts.</strong>`,
                    "sp-challenge": "Our client sought to create a soccer-centric platform that would facilitate the discovery of fellow soccer players and enable them to get matched easily. The app needed to handle various aspects, such as field selection, player ratings, and a seamless payment system that would pay the players after each game. To ensure maximum reach, the app had to be compatible with both Android and iOS devices. The primary challenges were to create an intuitive user interface, develop an efficient matching algorithm, and ensure secure and reliable payment processing.",
                    "sp-solution": "Our team developed a mobile application that addressed all the client's requirements. The key features of the app included:",
                    "sp-solution1": "User Profiles: Users could create profiles, inputting their skill level, preferred position, and availability, which enabled the app to match them accurately with other players.",
                    "sp-solution2": "Field Selection: The app offered an integrated map functionality that allowed users to select nearby soccer fields, ensuring convenient access for all participants.",
                    "sp-solution3": "Matching Algorithm: Our developers designed a sophisticated algorithm that considered various factors such as skill level, position, location, and availability to generate suitable matches for players.",
                    "sp-solution4": "Player Ratings: To maintain high-quality experiences, the app incorporated a peer-review system, enabling users to rate their fellow players after each match.",
                    "sp-solution5": "Payment System: We integrated a secure, in-app payment system that facilitated smooth transactions between players, ensuring prompt payments after games.",
                    "sp-solution6": "Multi-platform Availability: To cater to a wider audience, we developed native apps for both Android and iOS platforms, ensuring seamless functionality on each.",
                    "sp-outcome": `<p>The <strong>Soccer Matchup Marketplace App</strong> was successfully launched on Android and iOS platforms, receiving <strong>widespread acclaim from users</strong>. The app has significantly <strong>simplified the process of finding soccer matches</strong>, improving accessibility and convenience for <strong>players across various skill levels</strong>. Key outcomes of the project include:</p>
                    <p><strong>Over 5,000 downloads</strong> within the first three months of launch, with a consistent <strong>4.5-star rating</strong> on both Android and iOS app stores. <strong>More than 500 active users</strong> participating in soccer games weekly, with <strong>95% of users reporting satisfaction</strong> with the app's match-making features. A <strong>30% increase</strong> in the frequency of soccer games played among users, demonstrating the app's effectiveness in encouraging a <strong>more active and engaged soccer community</strong>. <strong>Secure and timely payments for players</strong>, with <strong>over 98%</strong> of transactions completed without issues, bolstering user trust in the platform. The app's player <strong>rating system</strong> has fostered a supportive and competitive environment, with <strong>90%</strong> of users actively providing feedback and ratings for their fellow players. Overall, the Soccer Matchup Marketplace App has proven to be a <strong>valuable resource for soccer</strong> enthusiasts, streamlining the process of finding matches and fostering a vibrant, engaged community.</p>`,
                    "dogit-challenge": "Our client aimed to create a one-stop-shop for pet owners to access veterinarian services, maintain their pet's health records, and purchase pet-related products. The app needed to be informative, user-friendly, and available on both iOS and Android platforms. The main challenges involved integrating various services into a single platform, creating an intuitive user interface, and ensuring secure and reliable transactions for products and services.",
                    "dogit-solution": "To address the client's requirements, we developed the DogIT App with the following key features:",
                    "dogit-solution1": "Vet Services Marketplace: Users can access a wide range of veterinarian services such as grooming, vaccinations, and deworming, provided by local professionals. The app allows users to book appointments and pay for services securely within the platform.",
                    "dogit-solution2": "Health Records & Reminders: The app enables users to maintain a health record for their pets, including vaccination history and deworming schedules. Additionally, the app sends timely reminders for upcoming vaccines and check-ups.",
                    "dogit-solution3": "Pet Products Store: We integrated an e-commerce platform within the app, allowing users to browse and purchase pet-related products, such as food, toys, and accessories, from reputable vendors.",
                    "dogit-solution4": "Breed Information & Care Tips: The app includes a library of detailed information about various dog breeds, including their temperament, care requirements, and potential health issues, to help users better understand and care for their pets.",
                    "dogit-solution5": "Multi-platform Availability: We developed native apps for both Android and iOS devices to cater to a wider audience and ensure seamless functionality on each platform.",
                    "dogit-outcome": `<p>The <strong>DogIT</strong> App was successfully launched on both Android and iOS platforms, garnering <strong>positive feedback</strong> from pet owners and veterinarians alike. The app has become a valuable resource for users, <strong>simplifying pet care</strong> management and facilitating access to veterinary services. Key outcomes of the project include:</p>
                    <p><strong>Over 7,000 downloads</strong> within the first three months of launch, with an average <strong>4.6-star rating</strong> on both Android and iOS app stores. A growing network of <strong>over 100 local veterinarians</strong> offering services through the app, providing users with a wide variety of options to choose from. <strong>More than 1,500 pet products</strong> sold within the first quarter, with <strong>99% of transactions</strong> processed securely and efficiently. A <strong>40% increase</strong> in scheduled vet appointments, vaccines, and grooming services among app users, showcasing the platform's effectiveness in <strong>promoting proactive pet care</strong>.</p>`,
                    "flux-challenge": "Flux, an electric vehicle rental company based in Austin, was looking for a practical way to translate their physical car rental service into a mobile app. They needed an easy-to-use platform for their customers and a seamless way to manage their fleet of 100 vehicles.",
                    "flux-solution": "Alluxi developed a mobile app tailored to Flux's needs. This app isn't just about car rentals - it takes it a step further. It helps customers find nearby electric stations and shows them the Flux vehicles available at each location. The app links with Flux's existing ERP system, Odoo, enabling real-time updates of vehicle availability. This solution has helped Flux streamline their service and ensure customers have accurate information at their fingertips.",
                    "flux-outcome": "The app has revolutionized how Flux operates and interacts with its customers. With up-to-date vehicle information, customers can rent an electric vehicle with ease, and Flux can manage their fleet more efficiently. It's a win-win solution that has enhanced Flux's services and elevated the user experience.",
                    "crushed-challenge": 'Our client envisioned an engaging social app, "Crushed!?", that went beyond the conventional dating apps. Their idea was to create a platform that could help individuals reconnect with their past crushes, and also foster new relationships.',
                    "crushed-solution": 'Alluxi took the challenge head-on. We designed and developed "Crushed!?", a unique social app that helps you find your long-lost crush based on location and circumstances of the past encounter. The app, however, doesn\'t stop at reigniting old flames. It also fosters new connections, allowing users to match with new people and spark fresh friendships or romances. Designed for both iOS and Android, "Crushed!?" is a versatile solution for those looking to reconnect or make new connections.',
                    "crushed-outcome": '"Crushed!?" is not just an app, it\'s a bridge to the past and a doorway to future connections. With its unique functionalities, it\'s been able to help users find long-lost crushes and cultivate new relationships. Our client\'s vision was successfully brought to life, and now "Crushed!?" is reshaping the way people connect, one crush at a time.',
                    "haig-challenge": 'The startup "Howamigoing" aimed to revolutionize employee feedback with their app. While they had a strong foundation, they needed expert help to design and develop new core functionalities, manage their Google Cloud Platform infrastructure, develop new GraphQL endpoints, and resolve critical bugs.',
                    "haig-solution": "Alluxi joined hands with \"Howamigoing\". Rather than starting from scratch, we integrated with their team to support their ongoing efforts. We managed their Google Cloud Platform infrastructure, ensuring their app's smooth operation. We developed new GraphQL endpoints to improve the efficiency and speed of data requests. Our team also designed and developed new software modules that elevated the user experience. Throughout the process, we identified and resolved business-critical bugs, ensuring the app's optimal performance. This was all achieved while maintaining open and close communication with the client to provide the best support possible.",
                    "haig-outcome": 'With Alluxi\'s technical and development support, "Howamigoing" successfully integrated new functionalities, improving its efficiency, user experience, and reliability. The app now empowers employees to receive frequent, meaningful feedback, thus aiding their professional growth. The partnership with Alluxi strengthened "Howamigoing\'s" position as a leading tool for employee feedback and development.',
                    "digitab-challenge": "Say goodbye to printed receipts.",
                    "digitab-solution": "Digitab is a startup focused on reducing the use of paper and retail shops.",
                    "digitab-outcome": "We designed, developed, and launched an hybrid mobile app that allows waiters to generate receipts digitally and allows, users to scan these digital receipts and save them on their account. Additionally, we developed an integration with POS by creating a digital printer that sends receipts data directly to a service on the Digitab platform. This way users can save their receipt digitally.",
                    "zplendid-challenge": "Our client, a renowned Bariatric Clinic, was grappling with a diverse range of challenges. Managing a growing community of 8,000 patients who had undergone or were preparing for bariatric surgery required a significant amount of resources and organization. \n\nThere was a need to streamline patient-doctor interactions, track progress effectively, and keep accurate medical records. \n\nAdditionally, the necessity to assess the eligibility of potential patients for surgery, manage appointment scheduling and travel arrangements, and integrate with home-scale devices was essential.",
                    "zplendid-solution": "To address these complex challenges, we developed Zplendid, an innovative mobile app that fundamentally redefined the bariatric surgery process. Zplendid not only enabled patients to track their journey from initial evaluation to post-surgery progress but also facilitated doctors in managing their patient's records and schedules.",
                    "zplendid-outcome": "The development of Zplendid spanned one year from analysis and design to testing and launch. Today, the app serves as a centralized system that successfully manages a community of 8,000 patients, greatly reducing the resources needed and improving the quality of patient care. The app has been well-received on both Android and iOS platforms.",
                    "posco-challenge": "POSCO, a leading South Korean Steel manufacturing company, sought to engage their workforce in Mexico more effectively. With a 1000 strong workforce, fostering interaction and communication, as well as rewarding employees for their hard work, was a complex task.",
                    "posco-solution": "Alluxi was entrusted with this challenge and developed the POSCO U&I app. This innovative platform not only allowed the company to reward their employees by generating points from various company activities, but it also introduced a social component to enhance interaction amongst employees.\nThe app included a store with a vast range of products where employees could exchange their points. It allowed for dynamic management and the offering of various product options through an admin panel.",
                    "posco-outcome": "The POSCO U&I app was well-received by the employees. The platform effectively increased engagement and fostered a sense of community within the company. Furthermore, it led to a significant reduction in employee turnover, validating the impact of the solution.",
                    thankyou: "Thankyou",
                    "thankyou-sub": "Thank you for your request",
                    "thankyou-desc": "We will contact you as soon as possible!",
                    "back-home": "Back to homepage",
                },
            },
            es: {
                translations: {
                    home: "Inicio",
                    "web-service": "Desarrollo Web",
                    "mobile-service": "Desarrollo de Aplicaciones Móviles",
                    "nearshore-services": "Desarrollo Nearshore",
                    "s-p-s": "IMPRESIONANTES, EFICIENTES Y SEGUROS",
                    "digital-products": "Transformamos ideas en negocios digitales.",
                    "we-build-tecnology": "Con 10 años de experiencia en tecnología estamos listos para ayudarte a acelerar tu crecimiento. Colabora con nosotros para construir productos digitales increíbles que potencien tu negocio. Descubre cómo hemos ayudado a startups a asegurar millones en finananciamiento.",
                    or: "o",
                    "next-project": "Iniciemos",
                    "book-a-call": "Agenda una asesoría gratuita",
                    "products-delivered": "Productos entregados y contando",
                    "trusted-by": "Empresas que confían en nosotros",
                    services: "Servicios",
                    "what-we-do": "Lo que hacemos",
                    "what-we-do-desc": "Impulsamos tu éxito con soluciones de software integrales, creadas a través de un proceso transparente de cuatro pasos: Escuchar, Planificar, Desarrollar y Refinar. Colaborar con nosotros amplifica la eficiencia, el alcance y la rentabilidad de la solución que estás por crear. En esta era digital, no somos ser solo una opción, queremos ser tu aliado esencial para el crecimiento.",
                    "custom-web-develop": "Desarrollo web",
                    "custom-web-develop-desc": "El desarrollo web permite a las empresas que buscan una experiencia consistente entre diferentes plataformas.",
                    "custom-web-develop-desc-sm": "",
                    "web-title": "Desarrollo Web a la Medida",
                    "web-subtitle": "Soluciones web que escalan con tu negocio y cautivan a tus usuarios.",
                    "web-hero-title": "Desarrollo web a la medida que impulsa el crecimiento de tu negocio",
                    "web-hero-subtitle": "Convertimos tus ideas en negocios digitales reales y rentables.",
                    "web-content": "En Alluxi, somos más que desarrollo web. Solucionamos tus más grandes retos de tecnología, creando experiencias web completas que ayudan a tu negocioa crecer.\n Nuestro equipo de desarrolladores y diseñadores expertos trabaja en estrecha colaboración contigo para entender a fondo tus necesidades y crear soluciones personalizadas para verdaderamente generar resultados comerciales. \nEstamos comprometidos en aprovechar la tecnología de vanguardia y las mejores prácticas para crear soluciones web que no solo sean visualmente atractivas, sino también robustas, seguras y escalables.",
                    "custom-mobile-develop": "Desarrollo móvil a la medida",
                    "custom-mobile-develop-desc": "Creamos aplicaciones personalizadas para mejorar la participación, accesibilidad y conectividad en tiempo real. Transforma tu negocio en la era móvil con nosotros!.",
                    "custom-mobile-develop-desc-sm": "",
                    "mobile-subtitle": "Creamos experiencias digitales, que capturan nuevos usuarios.",
                    "mobile-desc": "95% de las personas cuentan con un teléfono móvil. Utilizar el poder de los celulares al día de hoy ya no es un lujo, es una necesidad para cualquier negocio. Redefine el éxito de tu negocio con nuestros servicios de desarrollo de aplicaciones móviles a la medida. ¿Listo para transformar tu visión en una app impresionante?\n Estamos a solo un clic de distancia.",
                    "mobile-hero-title": "Tu negocio al alcance de los dedos",
                    "mobile-hero-subtitle": "Tu visión, nuestra experiencia",
                    "mobile-hero-desc": "Combinamos tecnología de punta, diseño innovador y experiencia estratégica para crear aplicaciones móviles que son más que simplemente funcionales: son transformadoras. Damos vida a tus ideas, entregando soluciones móviles que cautivan a los usuarios y fomentan el crecimiento empresarial.",
                    "nearshore-service": "Desarrollo Nearshore",
                    "nearshore-service-desc": "Nuestro equipo de profesionales proporcionan soluciones rentables y de alta calidad para el desarrollo, diseño, mantenimiento, control de calidad, pruebas y soporte de software.",
                    "nearshore-service-desc-sm": "",
                    "nearshore-title": "Servicios de Desarrollo de Software Nearshore de Clase Mundial",
                    "nearshore-subtitle": "Colaboración cercana,\nde alta calidad\n y rentable.",
                    "nearshore-subtitle-2": "We excel in providing top-tier nearshore services. Leveraging years of experience, we deliver affordable software solutions that bridge the distance, ensuring smooth project execution and close collaborations. Let our expertise amplify your technology vision.",
                    "nearshore-hero-title": "Tu Aliado de Confianza para el Desarrollo de Software Nearshore",
                    "nearshore-hero-subtitle": "Talento global para impulsar tu negocio, a una fracción del costo.",
                    "nearshore-content": "Superamos los límites geográficos para ofrecerte servicios de desarrollo de software nearshore de la más calidad. Hablamos un perfecto inglés, compartimos zona horaria contigo y seguimos las mejores prácticas de desarrollo. \nNuestro equipo de expertos en Python, Django y React (entre otras tecnologías) están dedicados a crear soluciones a medida que satisfacen las necesidades únicas de cada negocio. Hemos apoyado a diversas industrias como: Finanzas, Salud, Educación, y más – a través de nuestros servicios de alta calidad y rentables.\nColaboramos estrechamente con tu equipo a través de una gestión de proyectos fluida, una comunicación frecuente y la entrega de resultados que superan expectativas. Con nosotros no solamente incrementarás la velocidad y calidad del desarrollo de software, si no también buscamos ser un socio confiable comprometido al 100% con el éxito de tu negocio.",
                    "nearshore-benefit-title": "Desarrollo Nearshore: tu éxito es nuestra meta",
                    "nearshore-benefit-subtitle": "Construyamos soluciones inteligentes y rentables juntos",
                    "nearshore-benefit1": "Maximiza ahorros con nuestro modelo de desarrollo nearshore.",
                    "nearshore-benefit2": "Disfruta de comunicación fluida con nuestro equipo bilingüe.",
                    "nearshore-benefit3": "Puedes confiar en la calidad garantizada de nuestros profesionales certificados.",
                    "nearshore-benefit4": "Coordina sin problemas gracias a la compatibilidad horaria.",
                    "nearshore-benefit5": "Aprovecha las perspectivas del mercado gracias a nuestra compatibilidad cultural.",
                    "nearshore-roles-title": "Roles disponibles",
                    "nearshore-roles-desc": "Conoce al equipo que dará vida a tu vision. En Alluxi, ofrecemos una variedad de roles profesionales diseñados para satisfacer las necesidades únicas de cada proyecto. Nuestros expertos en servicios de desarrollo están listos para enfrentar sus desafíos y entregar soluciones innovadoras y de alta calidad.",
                    "ux/ui-title": "Creamos experiencias impactantes con un diseño UI/UX increíble",
                    "ux/ui-subtitle": "Convertimos flujos complejos en un placer para los usaurios con diseño intuitivo",
                    "ux/ui-desc-title": "Diseñamos interfaces centradas en el usuario para una mejor conversión",
                    "ux/ui-desc-subtitle": "Creamos experiencias digitales a través de soluciones innovadoras utilizando UI/UX",
                    "ux/ui-desc": "Creemos en el poder del diseño para mejorar las experiencias digitales. Ofrecemos servicios completos de UI/UX que ponen a los usuarios en el centro de nuestro proceso de diseño. Nuestro equipo de diseñadores UI/UX trabajan estrechamente con los clientes para entender sus metas y público objetivo, creando interfaces intuitivas, atractivas y visualmente convincentes que satisfacen las necesidades de los usuarios y fomentan el crecimiento del negocio.",
                    "ux/ui-benefit-title": "Transforma tu presencia digital con el poder de UI/UX",
                    "ux/ui-benefit-subtitle": "Experimenta la diferencia que una experiencia de usuario de alta calidad puede hacer",
                    "ux/ui-benefit1": "Aumenta el tiempo en pantalla y satisfacción del usuario",
                    "ux/ui-benefit2": "Mejora la usabilidad, funcionalidad y fácilidad de uso",
                    "ux/ui-benefit3": "Impulsa tasas de conversión más altas",
                    "ux/ui-benefit4": "Fomenta la lealtad y retención de tus usuario",
                    "ux/ui-benefit5": "Mejora la experiencia general de tus apps",
                    "ux/ui-benefit6": "Aumentamos el crecimiento y rentabilidad de tu negocio",
                    cloud: "Servicios en la nube",
                    "cloud-title": "Navega la nube con precisión y experiencia",
                    "cloud-subtitle": "Empoderamos negocios a través de soluciones de nube poderosas y escalables",
                    "cloud-desc-title": "Optimizamos operaciones con servicios de nube seguros y robustos",
                    "cloud-desc-subtitle": "Aprovecha el poder de la nube con las soluciones personalizadas",
                    "cloud-desc": "Ponemos a disposición de las empresas pequeñas, medianas y grandes todo el potencial del cómputo en la nube. Nuestros servicios en la nube están diseñados para impulsar la eficiencia, la agilidad y la escalabilidad, garantizando altos niveles de seguridad. Nos asociamos con las empresas para comprender sus necesidades únicas y brindando soluciones en la nube personalizadas que les ayudan a optimizar las operaciones, reducir costos y fomentar la innovación. Confía en Alluxi para guiar tu viaje en la nube y desbloquear el potencial completo de las herramientas disponibles.",
                    "cloud-benefit-title": "Aprovecha los beneficios del computo en la nube",
                    "cloud-benefit-subtitle": "Desata el crecimiento e innovación de tu negocio",
                    "cloud-benefit1": "Mejora la eficiencia y agilidad operativa",
                    "cloud-benefit2": "Reduce significativamente tus costos de infraestructura",
                    "cloud-benefit3": "Impulsa la innovación y escalabilidad",
                    "cloud-benefit4": "Asegura altos niveles de seguridad de datos",
                    "cloud-benefit5": "Mejora la continuidad de tu negocio y la recuperación ante desastres",
                    "cloud-benefit6": "Habilita la capacidad de trabajar de manera remota",
                    "ai-title": "Aprovechamos la inteligencia artificial para impulsar nuevos negocios y soluciones",
                    "ai-subtitle": "Empoderamos empresas con soluciones inteligentes y escalables de AI",
                    "ai-desc-title": "Aprovechamos la IA para transformar las operaciones de negocios",
                    "ai-desc-subtitle": "Construyendo negocios más inteligentes con soluciones personalizadas de AI",
                    "ai-desc": "En Alluxi, creemos en el poder transformador de la AI  y cómo esta va a revolucionar a las empresas. Ofrecemos servicios integrales de AI que buscan mejorar los procesos empresariales, optimizar la toma de decisiones y abrir nuevas vías de crecimiento. Nuestro equipo de expertos en AI trabaja en colaboración con los clientes para entender su contexto de negocio y sus metas, entregando soluciones personalizadas de AI que impulsan la eficiencia, la productividad y ofrecen una ventaja competitiva. Confía en Alluxi para desbloquear el potencial de la AI para tu negocio.",
                    "ai-benefit-title": "Cosecha los beneficios de la AI",
                    "ai-benefit-subtitle": "Impulsamos la innovación y el crecimiento del negocio con AI",
                    "ai-benefit1": "Simplifica procesos de negocios",
                    "ai-benefit2": "Mejora la toma de decisiones",
                    "ai-benefit3": "Impulsa la productividad",
                    "ai-benefit4": "Mejora la experiencia y el compromiso de tus clientes",
                    "ai-benefit5": "Fomenta la innovación y el crecimiento",
                    "ai-benefit6": "Obtén una ventaja competitiva",
                    team: "Team",
                    developers: "Dearrolladores",
                    designers: "Diseñadores",
                    "project-managers": "Gerentes de proyecto",
                    testers: "Testers",
                    "our-work": "Nuestro trabajo",
                    "our-work-title": "Productos haciendo la diferencia",
                    "our-work-desc": "Potenciamos empresas y transformamos vidas a través de soluciones innovadoras",
                    "see-our-services": "Vea nuestros servicios en acción",
                    "our-work-button": "Mira nuestro trabajo reciente",
                    methodologies: "METODOLOGIAS",
                    "how-we-work": "Cómo trabajamos",
                    "how-we-work-desc": "Nuestro proceso inicia entendiendo tus necesidades específicas, después identificamos las historias de usuario. Colaboramos estrechamente contigo durante todo el desarrollo del proyecto, asegurando una comunicación abierta y constante para lograr resultados que superen las expectativas. Tu éxito es y siempre será nuestra prioridad, y con nuestro enfoque profesional, pero cercano, te proporcionamos las mejores soluciones adaptadas a tus objetivos comerciales. Conoce el proceso tu mismo.",
                    prototype: "PROTOTIPADO",
                    "do-it-right": "Reduce tiempos y costos",
                    "do-it-right-desc": "Te ayudamos a reducir tanto el tiempo como los costos de desarrollo. Crear un prototipo no solo te permite visualizar tu producto final de una manera tangible y temprana, sino que también nos permite probar y validar tus ideas antes de invertir en la construcción completa del producto. \nEste proceso inicial de prototipado es crucial para identificar posibles obstáculos o áreas de mejora, ahorrándote tiempo y dinero en futuras modificaciones. En este paso, tomamos en cuenta tus requisitos, analizamos la funcionalidad y enfocamos nuestra energía en garantizar que el diseño del producto esté alineado con tus objetivos comerciales.",
                    MVP: "Desarrollo MVP",
                    "get-on-road": "Ponte en marcha",
                    "get-on-road-desc": "Este es el primer paso hacia la transformación digital de tu negocio. Aquí, facilitamos tu viaje de desarrollo de software, asegurándonos de que entiendas el proceso y sepas exactamente qué esperar. Cuando eliges trabajar con nosotros, no solo contratas a un equipo de desarrolladores, sino a un socio estratégico comprometido con el éxito de tu proyecto.\n Nos encargamos de entender a fondo tus necesidades y objetivos para ofrecerte las soluciones más adecuadas. Nuestro equipo de expertos en desarrolladores está listo para tus retos y convertir tus ideas en soluciones innovadoras que impulsen tu negocio. \nNo importa en qué etapa esté tu proyecto, estamos aquí para ayudarte. Empieza hoy tu viaje hacia la innovación digital con nosotros, porque en esta era de constante evolución, no hay tiempo que perder. ¡Vamos a ponerte en marcha!",
                    support: "SOPORTE",
                    "we-have-back": "Te tenemos cubierto",
                    "we-have-back-desc": "Priorizamos la seguridad, confiabilidad y soporte de tu proyecto. Nuestro equipo garantiza medidas de seguridad de primer nivel, siguendo las mejores prácticas de la industria y proporcionando encriptación de tus datos sensibles. Entregamos soluciones de alta calidad, cada detalle de tu aplicación para un rendimiento impecable. Nuestro soporte se extiende desde el inicio del proyecto hasta las actualizaciones continuas después del lanzamiento. Construir relaciones sólidas con nuestros clientes y garantizar tu satisfacción y éxito es nuestra prioridad.",
                    "why-choose-us": "¿POR QUÉ ELEGIRNOS?",
                    "our-customers-love": "Nuestros clientes aman lo que hacemos —— y nosotros también.",
                    "we-use-modern-tool": "Usamos herramientas modernas y efectivas.",
                    "we-improve": "Mejoramos constantemente nuestras metodologías de trabajo para brindarte un mejor servicio.",
                    "we-look": "Siempre buscamos la solución más adecuada a precios accesibles.",
                    "swiper-zubillaga": "“Siempre entendiendo nuestros requerimientos al detalle, diseño y desarrollo de software de alta calidad.”",
                    awards: "Premios y reconocimientos",
                    "blog-title": "Todo lo que necesitas saber y más",
                    "blog-desc": "Brindamos consejos prácticos, estrategias y técnicas para hacer crecer tu negocio.",
                    "see-all-post": "Ver más",
                    "see-more": "Ver más",
                    tools: "HERRAMIENTAS",
                    "our-teck-stack": "Nuestro stack tecnológico",
                    "our-teck-desc": "Tenemos gran experiencia en diversas tecnologías para el desarrollo de software web y móvil. Esta experiencia, junto con nuestra profunda conexión con nuestros clientes, nos permite ofrecer soluciones a medida que impulsan el crecimiento de tu negocio.",
                    "and-more": "Y más",
                    "we-have-all": "Tenemos lo que necesitas",
                    "we-have-all-desc": "Vivimos en la intersección de la tecnología y la innovación. Con amplia experiencia en Inteligencia Artificial, Servicios de UX/UI, Desarrollo de software y análisis, combinamos nuestra experiencia con enfoques modernos para crear soluciones a medida. Ya sea mediante diseños intuitivos, software inteligente o análisis detallados, nuestra te ayudará a dar vida a tu visión.",
                    "user-experience": "Experiencia con el Usuario",
                    "user-experience-desc": "Estrategia, tecnología y creatividad con el objetivo de superar siempre las expectativas",
                    "cloud-services": "Servicios en la Nube",
                    "cloud-services-desc": "Opciones confiables, seguras y rápidas de servicios en la nube para ahorrar tiempo y dinero.",
                    "ai-integration": "Integración de IA",
                    "ai-integration-desc": "Potencia tu plataforma existente con una poderosa integración de IA para un rendimiento mejorado y capacidades avanzadas.",
                    analytics: "Analítica",
                    "analytics-desc": "Ofrecemos inteligencia de negocios a nivel empresarial y soluciones analítica para para permitir a los usuarios analizar su información.",
                    "products-difference": "Productos que marcan la diferencia",
                    "analytics-sub": "Aprovechando el Poder de los Datos",
                    "analytics-title": "Desde el seguimiento de usuarios hasta soluciones avanzadas de datos",
                    "analytics-content": "Más allá del mero seguimiento de usuarios, nuestro enfoque abarca análisis de datos integral, metodologías robustas de ciencia de datos y confiable ingeniería de datos. Nuestro compromiso: Convertir datos brutos en insights accionables para su negocio.",
                    "analytics-sub-2": "Herramientas que utilizamos",
                    "analytics-title-2": "Empoderando Negocios con Técnicas de Vanguardia",
                    "analytics-content-2": "Nuestro conjunto de herramientas y metodologías avanzadas en análisis, ciencia e ingeniería de datos garantiza precisión y escalabilidad. Utilizando técnicas de vanguardia, navegamos el paisaje de datos, impulsando resultados significativos para su marca.",
                    "analytics-benefits-subtitle": "Ventajas de un Enfoque Basado en Datos",
                    "analytics-benefits-title": "Beneficios integrales de los servicios de datos",
                    "analytics-benefit1": "Potencia la inteligencia empresarial.",
                    "analytics-benefit2": "Optimiza la eficiencia operativa.",
                    "analytics-benefit3": "Predice tendencias del mercado.",
                    "analytics-benefit4": "Mejora la personalización del cliente.",
                    "analytics-benefit5": "Impulsa innovación con insights.",
                    "case-study": "Caso de estudio",
                    plataform: "Plataforma",
                    technologies: "Tecnologías",
                    "react-software-development": "Desarrollo de Software en React",
                    "react-title": "Revoluciona Tu Negocio con Soluciones Personalizadas en React",
                    "react-description": "Aprovecha el poder de React para construir aplicaciones escalables e intuitivas que deleiten a los usuarios y fomenten el crecimiento.",
                    "react-service-title": "Servicios de Desarrollo en React",
                    "react-service-description": "Desde la idea inicial hasta el despliegue, nuestros expertos en React te guiarán en cada paso, asegurando calidad y rendimiento de primer nivel.",
                    "react-service-name1": "Desarrollo Web en React",
                    "react-service-description1": "Transforma tu experiencia digital con aplicaciones web ágiles y responsivas en React.",
                    "react-service-name2": "Desarrollo en React Native",
                    "react-service-description2": "Expande tu alcance con aplicaciones móviles multiplataforma construidas con React Native.",
                    "react-service-name3": "Consultoría en React",
                    "react-service-description3": "Obtén insights estratégicos y experiencia técnica de nuestros consultores especializados en React.",
                    "react-what-we-do-title": "Por Qué Elegirnos para Desarrollo en React",
                    "react-what-we-do-description": "Nuestro enfoque combina tecnología de vanguardia con un profundo entendimiento de las necesidades de tu negocio. Estamos comprometidos en entregar soluciones robustas en React que ofrezcan una excelente experiencia de usuario y retorno de inversión.",
                    "python-software-development": "Desarrollo de Software en Python",
                    "python-title": "Desbloquea un mundo de posibilidades desarrollando en Python",
                    "python-description": "Utiliza la versatilidad de Python para construir aplicaciones poderosas que resuelvan problemas reales.",
                    "python-service-title": "Somos expertos en desarrollo utilizando Python",
                    "python-service-description": "Ya sea desarrollo web, análisis de datos o IA, nuestros expertos en Python están a la altura del proyecto que deseas ralizar.",
                    "python-service-name1": "Desarrollo Web",
                    "python-service-description1": "Construye aplicaciones web altamente seguras y escalables adaptadas a las necesidades de tu negocio.",
                    "python-service-name2": "Machine Learning",
                    "python-service-description2": "Utiliza las avanzadas bibliotecas de Python para machine learning y obtén insights accionables a partir de tus datos.",
                    "python-service-name3": "Automatización",
                    "python-service-description3": "Automatiza tu flujo de trabajo y procesos con nuestras soluciones personalizadas en Python.",
                    "python-what-we-do-title": "Nuestra experiencia en Python",
                    "python-what-we-do-description": "Ofrecemos una amplia gama de servicios de desarrollo en Python, priorizando la calidad del código, escalabilidad y entrega puntual.",
                    "aws-title": "Construye y escala con soluciones en la nube de AWS",
                    "aws-description": "Aprovecha las extensas capacidades de AWS para construir, desplegar y escalar aplicaciones de manera eficiente.",
                    "aws-service-title": "Servicios en la nube de AWS",
                    "aws-service-description": "Aprovecha nuestra experiencia en AWS para arquitecturar, migrar y gestionar soluciones en la nube complejas.",
                    "aws-service-name1": "Migración a la Nube",
                    "aws-service-description1": "Mueve tus aplicaciones y datos a la nube de manera fluida con nuestros servicios de migración a AWS.",
                    "aws-service-name2": "Soluciones DevOps",
                    "aws-service-description2": "Automatiza tu pipeline de CI/CD y mejora la entrega con herramientas de DevOps de AWS.",
                    "aws-service-name3": "Servicios Gestionados",
                    "aws-service-description3": "Deja que manejemos las complejidades de la gestión de AWS para que puedas centrarte en tu negocio principal.",
                    "aws-what-we-do-title": "Por Qué Elegirnos para Servicios en AWS",
                    "aws-what-we-do-description": "Nuestros profesionales certificados en AWS aseguran que obtengas el máximo rendimiento de las funcionalidades de AWS, maximizando el rendimiento mientras minimizas los costos.",

                    "js-title": "Desarrollo de software en JavaScript que transforma experiencias web",
                    "js-description": "Crea aplicaciones web interactivas y dinámicas utilizando el poder de los modernos frameworks de JavaScript.",
                    "js-service-title": "Servicios de desarrollo en JavaScript",
                    "js-service-description": "Nuestros expertos en JavaScript son expertos en todos los principales frameworks y bibliotecas de JS, ofreciéndote una amplia gama de opciones.",
                    "js-service-name1": "Desarrollo de Front-end",
                    "js-service-description1": "Ofrece interfaces de usuario visualmente impactantes y altamente responsivas.",
                    "js-service-name2": "Desarrollo de Back-end",
                    "js-service-description2": "Construye aplicaciones confiables y escalables en el lado del servidor utilizando Node.js.",
                    "js-service-name3": "Desarrollo de SPA y PWA",
                    "js-service-description3": "Crea SPAs y PWAs que ofrecen experiencias similares a las nativas.",
                    "js-what-we-do-title": "Por qué elegirnos para desarrollo de tu proyecto en JavaScript",
                    "js-what-we-do-description": "Nuestros desarrolladores se mantienen actualizados con las últimas tecnologías de JavaScript, asegurando que tu aplicación sea tanto moderna como a prueba de futuro.",

                    "django-title": "Desarrollo Web Rápido y Escalable con Django",
                    "django-description": "Opta por Django para construir aplicaciones web seguras y fácilmente mantenibles que puedan escalar sin esfuerzo.",
                    "django-service-title": "Servicios de Desarrollo en Django",
                    "django-service-description": "Desde startups hasta empresas, nuestros expertos en Django pueden construir soluciones adaptadas para satisfacer las necesidades de tu negocio.",
                    "django-service-name1": "Aplicaciones Web Personalizadas",
                    "django-service-description1": "Obtén soluciones web personalizadas construidas en el poderoso backend de Django.",
                    "django-service-name2": "Desarrollo de API",
                    "django-service-description2": "Desarrolla APIs robustas con el Framework de Django Rest para tus aplicaciones móviles y web.",
                    "django-service-name3": "Soluciones de E-commerce",
                    "django-service-description3": "Lanza tu tienda en línea de manera rápida y segura con nuestras soluciones de e-commerce en Django.",
                    "django-what-we-do-title": "Por Qué Elegirnos para Desarrollo en Django",
                    "django-what-we-do-description": "Nuestros desarrolladores de Django están bien versados en las mejores prácticas y metodologías, asegurando un desarrollo rápido sin comprometer la calidad.",
                    "react-native-title": "Eleva tu experiencia móvil con React Native",
                    "react-native-description": "Aprovecha el poder de React Native para construir aplicaciones móviles escalables y de alto rendimiento.",
                    "react-native-service-title": "Servicios de desarrollo React Native",
                    "react-native-service-description": "Desbloquee experiencias móviles excepcionales con nuestros completos servicios de desarrollo de React Native.",
                    "react-native-service-name1": "Consulta de React Native",
                    "react-native-service-description1": "Obtenga orientación experta para migrar a o iniciar un nuevo proyecto en React Native.",
                    "react-native-service-name2": "Diseño de UI/UX en React Native",
                    "react-native-service-description2": "Logre una interfaz de usuario sensible y visualmente impactante con nuestra experiencia en diseño.",
                    "react-native-service-name3": "Mantenimiento de apps React Native",
                    "react-native-service-description3": "Asegura que tu aplicación permanezca actualizada y libre de errores con nuestros servicios de mantenimiento continuo.",
                    "react-native-what-we-do-title": "Qué hacemos en React Native",
                    "react-native-what-we-do-description": "Desde la consulta inicial hasta el mantenimiento posterior al lanzamiento, cubrimos todos los aspectos del desarrollo de React Native. Nuestro equipo se enfoca en escribir un código limpio, garantizar la escalabilidad y ofrecer experiencias de usuario inigualables.",

                    "ionic-title": "Construye aplicaciones versátiles con Ionic",
                    "ionic-description": "Utiliza Ionic para un desarrollo rápido y compatibilidad entre plataformas.",
                    "ionic-service-title": "Servicios de desarrollo Ionic",
                    "ionic-service-description": "Aproveche la tecnología agnóstica de plataforma de Ionic para soluciones móviles versátiles y rentables.",
                    "ionic-service-name1": "Desarrollo de aplicaciones Ionic",
                    "ionic-service-description1": "Construya aplicaciones robustas que funcionen sin problemas en múltiples plataformas.",
                    "ionic-service-name2": "Integración Ionic",
                    "ionic-service-description2": "Integre fácilmente con diversos servicios de backend y de terceros.",
                    "ionic-service-name3": "Optimización del rendimiento de Ionic",
                    "ionic-service-description3": "Optimice el rendimiento de su aplicación para una experiencia de usuario más fluida.",
                    "ionic-what-we-do-title": "Qué hacemos en Ionic",
                    "ionic-what-we-do-description": "Desde el prototipo hasta la producción, ofrecemos una gama completa de servicios de desarrollo Ionic que darán vida a su visión móvil.",

                    "postgres-title": "Potencia tus datos con PostgreSQL",
                    "postgres-description": "Experimente soluciones de base de datos robustas y escalables con PostgreSQL.",
                    "postgres-service-title": "Servicios de desarrollo PostgreSQL",
                    "postgres-service-description": "Cree, gestione y escale su base de datos con nuestros servicios especializados de PostgreSQL.",
                    "postgres-service-name1": "Diseño de bases de datos",
                    "postgres-service-description1": "Diseña una base de datos bien estructurada y eficiente adaptada a las necesidades de su negocio.",
                    "postgres-service-name2": "Migración de datos",
                    "postgres-service-description2": "Migre sus datos a PostgreSQL de manera segura sin tiempo de inactividad o pérdida.",
                    "postgres-service-name3": "Optimización de rendimiento",
                    "postgres-service-description3": "Maximiza el rendimiento de su base de datos con ajustes avanzados.",
                    "postgres-what-we-do-title": "Qué hacemos en PostgreSQL",
                    "postgres-what-we-do-description": "Nuestros servicios de PostgreSQL abarcan todo, desde la configuración inicial hasta el mantenimiento, asegurando que sus datos estén siempre seguros y accesibles.",

                    "vue-title": "Revoluciona tus aplicaciones web con Vue.js",
                    "vue-description": "Implementa Vue.js para una experiencia de usuario en el front-end ligera pero poderosa.",
                    "vue-service-title": "Servicios de desarrollo de Vue",
                    "vue-service-description": "Redefina la interacción del usuario y la interfaz de usuario con nuestros expertos servicios de desarrollo de Vue.js.",
                    "vue-service-name1": "Desarrollo personalizado de Vue.js",
                    "vue-service-description1": "Soluciones personalizadas que se centran en optimizar la participación del usuario.",
                    "vue-service-name2": "Desarrollo de SPA en Vue.js",
                    "vue-service-description2": "Cree aplicaciones de una sola página que ofrezcan experiencias de usuario rápidas y fluidas.",
                    "vue-service-name3": "Soporte y mantenimiento de Vue.js",
                    "vue-service-description3": "Actualizaciones regulares y correcciones rápidas para mantener su aplicación Vue.js funcionando sin problemas.",
                    "vue-what-we-do-title": "Qué hacemos en Vue",
                    "vue-what-we-do-description": "Ya sea un desarrollo personalizado o un soporte continuo, ofrecemos servicios de Vue.js integrales que se alinean con los objetivos de su negocio.",
                    "industry-title": "Industrias en las que destacamos",
                    "health-care-description": "Optimice procesos médicos y mejore la atención al paciente con nuestras innovadoras soluciones de atención médica.",
                    "finance-description": "Mejore sus operaciones financieras y asegure las transacciones de los usuarios con nuestras sólidas soluciones FinTech.",
                    "education-description": "Empodere a estudiantes y educadores por igual a través de nuestras plataformas educativas intuitivas y dinámicas.",

                    challenges: "Retos",
                    "problems-solve": "Problemas a resolver",
                    solutions: "Soluciones",
                    "final-results": "Resultados finales",
                    about: "Nosotros",
                    "about-title": "Llevamos tus ideas a productos reales y exitosos",
                    "about-us-title": "Convertimos sus ideas en reales y exitosos productos",
                    "about-us-desc": "No queremos hacer sólo lo que nos pidas, queremos ir más allá, comprendiendo e implicándonos en tu proceso de desarrollo.",
                    "about-desc": "Entregamos a tiempo y en presupuesto, productos de calidad que superan las expectativas.",
                    "about-question": "¿Qué significa esto para ti?",
                    "b-ideas": "Dar vida a tus ideas con servicios de desarrollo a medida.",
                    "b-cost": "Soluciones nearshore rentables con un equipo local.",
                    "b-flexibility": "Flexibilidad para satisfacer tus necesidades y superar expectativas.",
                    "b-communication": "Comunicación abierta para un proceso de desarrollo colaborativo.",
                    "b-innovative": "Soluciones innovadoras para que tu producto destaque.",
                    "b-satisfaction": "Tu satisfacción es nuestra mayor prioridad.",
                    commitment: "Compromiso",
                    "our-mission": "Nuestra Misión",
                    "our-mission-desc": "No queremos hacer solamente lo que nos pidas. Queremos ir más allá e involucrarnos en tu proceso de desarrollo.\n\nQueremos conocer la misión detrás tu negocio entender por qué va a ese lugar y ayudarte a llegar a él.\n\nConvertirnos en un aliado tecnológico de tu negocio. Para que tu puedas enfocarte en los aspectos más importantes. Nuestro enfoque nos permite construir software con un futuro en mente; a la vez que construimos relaciones duraderas con nuestros clientes.\n\nCreemos que el éxito de un proyecto no se alcanza al final de este, si no que se repite constamente a lo largo de su evolución.",
                    "our-values": "Nuestros Valores",
                    passion: "Pasión",
                    "passion-desc": "Amamos lo que hacemos y creemos en el aprendizaje constante.",
                    teamwork: "Trabajo en equipo",
                    "teamwork-desc": "Ponemos en conjunto nuestras habilidades para lograr grandes resultados en un ambiente colaborativo, de reconocimiento y respeto.",
                    responsibility: "Responsabilidad",
                    "responsibility-desc": "Siempre entregamos los objetivos que se plantearon desde el inicio y aceptamos los desafíos que éstos conlleven.",
                    accountability: "Dueños de resultados",
                    "accountability-desc": "Estamos orgullosos del trabajo que realizamos. Nuestra reputación es nuestra carta de presentación.",
                    ally: "Somos un aliado",
                    "ally-desc": "No somos un proveedor de software más. Queremos ser un verdadero aliado de tu proyecto y verlo triunfar.",
                    careers: "Carreras",
                    "careers-title": "Ayúdanos a ofrecer excelentes productos",
                    "career-subtitle": "Modela el futuro con tu talento",
                    "careers-desc": "Descubre la oportunidad de ser parte de Alluxi, donde adoptamos un entorno de trabajo remoto y atendemos al excepcional talento en LATAM. Vive la libertad de diseñar tu propio equilibrio entre trabajo y vida personal, mientras te beneficias de una empresa solidaria que valora tu bienestar y crecimiento. Con nosotros, encontrarás el espacio ideal para prosperar profesional y personalmente. ¡Únete a nuestra dinámica familia hoy y emprende un extraordinario viaje con nosotros!",
                    remote: "Remoto",
                    benefits: "Beneficios",
                    "work-with-us": "Trabaja con nosotros",
                    "remote-first": "Remoto primero",
                    "flexible-time": "Tiempo de trabajo flexible",
                    "self-improvement": "Presupuesto de mejora personal",
                    "vacation-days": "10 días de vacaciones desde el primer año",
                    "open-roles": "Vacantes",
                    engineering: "Ingenierías",
                    "full-stack-eng": "Ingeniero de Software Full Stack",
                    "backend-eng": "Ingeniero Backend",
                    "python-eng": "Ingeniero Senior Python",
                    design: "Trabajos de Diseño / UX",
                    "ux-designer": "Diseñador UI/UX",
                    "product-designer": "Diseñador de productos",
                    sales: "Trabajos de Ventas",
                    "sales-develop": "Representante de Desarrollo de Ventas",
                    management: "Trabajos de Operaciones / Management",
                    programming: "Trabajos de Programación",
                    "full-stack-python-dev": "Desarrollador Full-Stack Python/React",
                    "full-stack-php-dev": "Desarrollador Full-Stack PHP/Laravel",
                    industries: "Industrias",
                    "how-can-we-help": "Cómo podemos ayudar",
                    value: "Valor",
                    "health-care": "Salud",
                    "health-care-desc": "En un campo tan vital como la salud, la transición hacia plataformas digitales personalizadas puede ser un desafío. En Alluxi, ofrecemos a los profesionales de la salud soluciones tecnológicas de vanguardia para transformar sus servicios de salud y mejorar la entrega de la atención médica.",
                    "health-care-desc_title": "Alluxi: Tu aliado en la transformación digital de la salud",
                    "health-care-desc_subtitle": "Creamos soluciones digitales personalizadas para la salud",
                    "health-care-desc_text": "Con una experiencia sólida en la industria de la salud, Alluxi ayuda a las organizaciones a desarrollar, adquirir e integrar soluciones digitales que generan resultados. Nuestro objetivo es trabajar en soluciones de software que optimicen y mejoren las operaciones de las organizaciones de la salud.",
                    "health-care-help-title": "Nuestro Enfoque",
                    "health-care-help-desc": "Reunimos innovación, experiencia y comprensión para diseñar y desarrollar soluciones impactantes. Nuestro enfoque meticuloso garantiza un lanzamiento exitoso y conforme a las regulaciones que se integra sin problemas en los sistemas existentes. Pero no nos detenemos en el lanzamiento: nuestros servicios de soporte continuo brindan actualizaciones de software, resolución de problemas y capacitación de usuarios para garantizar que nuestras soluciones satisfacen continuamente las necesidades de los usuarios. A medida que el panorama de su industria evoluciona, adaptamos nuestro soporte para asegurar el éxito duradero de nuestros productos.",
                    "health-care-design-title": "Diseño y Desarrollo",
                    "health-care-design-desc": "Creamos soluciones impactantes de atención médica, fusionando innovación y experiencia técnica. Nuestro enfoque está en desarrollar aplicaciones centradas en el paciente y sistemas eficientes de gestión de datos de salud. Nuestra comprensión de los desafíos únicos de la salud nos permite diseñar con seguridad, privacidad y accesibilidad como prioridades. Estamos comprometidos a mejorar las experiencias y resultados de atención médica a través de un diseño y desarrollo efectivos.",
                    "health-care-launch-title": "Lanzamiento ",
                    "health-care-launch-desc": "Nuestro enfoque al detalle garantiza un lanzamiento exitoso de tu producto o servicio de salud. Garantizamos el cumplimiento de las regulaciones, una integración fluida con los sistemas existentes y un impacto inicial positivo. Nuestra estrategia minimiza la interrupción maximizando los beneficios, preparando a tu equipo y usuarios para una transición sin problemas.",
                    "health-care-support-title": "Soporte",
                    "health-care-support-desc": "Nuestro soporte no termina en el lanzamiento. Ofrecemos asistencia continua, incluyendo actualizaciones de software, solución de problemas y capacitación de usuarios. Monitoreamos el rendimiento de nuestras soluciones, asegurándonos de que satisfacen constantemente las necesidades de los usuarios. A medida que el panorama de la salud cambia, adaptamos nuestros servicios de soporte para igualar, asegurando el éxito a largo plazo de nuestros productos.",
                    "health-care-benefit-title": "Beneficios de las Soluciones Digitales de Alluxi en Salud",
                    "health-care-benefit-desc": "Aprovecha la transformación digital en el sector de la salud. Nuestras soluciones tecnológicas no solo mejoran la experiencia del usuario, sino que también optimizan la entrega de atención médica, garantizan el cumplimiento regulatorio, y facilitan la interoperabilidad de la información de salud.",
                    "health-care-benefit1": "<p><strong>Plataformas Médicas de Emparejamiento:</strong> Construimos una plataforma de comercio electrónico fácil de usar para compradores y vendedores en la industria médica, con una gestión de alta calidad que cultiva una experiencia amigable para el usuario.</p>",
                    "health-care-benefit2": "<p><strong>Servicios de Salud Personalizados:</strong> Creamos soluciones digitales personalizadas que mejoran la eficiencia en la distribución de servicios e información relacionados con la salud.</p>",
                    "health-care-benefit3": "<p><strong>Experiencias Digitales para Pacientes:</strong> Desarrollamos plataformas digitales únicas que fortalecen la relación entre los pacientes y los profesionales médicos, siempre con el paciente en el centro de cada interacción.</p>",
                    "health-care-benefit4": "<p><strong>Soluciones Integradas de Salud:</strong> Asesoramos a los proveedores de atención médica en la creación de soluciones digitales totalmente integradas para ofrecer servicios de telemedicina confiables.</p>",
                    "health-care-benefit5": "<p><strong>Interoperabilidad de la Información de Salud:</strong> Nuestras soluciones permiten el intercambio seguro de información de salud, mejorando la coordinación y la calidad de la atención.</p>",
                    "health-care-benefit6": "<p><strong>Cumplimiento Regulatorio:</strong> Aseguramos que nuestras soluciones cumplan con todas las normativas y estándares de la industria de la salud.</p>",
                    finance: "Finanzas",
                    "finance-desc": "Revolucionando la Industria de Servicios Financieros a Través de la Transformación Digital En el dinámico panorama de los servicios financieros, la transformación digital es clave para mejorar las experiencias de los clientes e impulsar el crecimiento. Desde atraer a nuevos clientes hasta simplificar las operaciones bancarias y aprovechar las tecnologías emergentes, las instituciones financieras deben combinar el diseño estratégico de productos con sólidas capacidades técnicas para proporcionar experiencias bancarias sin interrupciones.",
                    "finance-desc_title": "Navega la Transformación Digital con Alluxi",
                    "finance-desc_subtitle": "Aprovecha la tecnología para crear las experiencias financieras inmersivas",
                    "finance-desc_text": "En Alluxi, fomentamos la colaboración sin fisuras con cada cliente para identificar nuevas oportunidades y diseñar hojas de ruta estratégicas. Nuestra experiencia en el desarrollo de software financiero nos permite revolucionar la forma en que las empresas operan en el sector financiero.",
                    "finance-help-title": "Nuestro Enfoque",
                    "finance-help-desc": "Combinamos innovación, experiencia y comprensión para crear potentes soluciones financieras. Nuestra estrategia de lanzamiento detallada garantiza una transición exitosa y conforme a la normativa. Después del lanzamiento, nuestro soporte continuo incluye actualizaciones, resolución de problemas y capacitación de usuarios, evolucionando a medida que cambia la industria financiera, garantizando el éxito a largo plazo de nuestros productos.",
                    "finance-design-title": "Diseño y Desarrollo",
                    "finance-design-desc": "Creamos soluciones financieras innovadoras, fusionando creatividad y dominio técnico. Nuestro equipo se especializa en aplicaciones amigables y sistemas robustos de gestión de datos financieros. Navegamos las complejidades de las finanzas, diseñando con seguridad, privacidad y cumplimiento en mente. Estamos dedicados a mejorar las experiencias y resultados financieros a través de nuestro diseño y desarrollo estratégico.",
                    "finance-launch-title": "Lanzamiento ",
                    "finance-launch-desc": "Nuestro enfoque integral garantiza un lanzamiento exitoso de tu producto o servicio financiero. Nos aseguramos de que haya conformidad regulatoria, integración fluida con los sistemas existentes y un fuerte impacto inicial. Preparamos a tu equipo y a los usuarios para una transición suave, minimizando las interrupciones y maximizando los beneficios.",
                    "finance-support-title": "Soporte",
                    "finance-support-desc": "Después del lanzamiento, brindamos soporte continuo, gestionando las actualizaciones de software, la solución de problemas y la capacitación de los usuarios. Supervisamos el rendimiento de la solución, asegurando una constante alineación con las necesidades de los usuarios. A medida que el panorama financiero evoluciona, nuestro soporte se adapta para garantizar el éxito duradero de nuestras soluciones.",
                    "finance-benefit-title": "Libera potencial con las soluciones financieras personalizadas de Alluxi",
                    "finance-benefit-desc": "Obtén los beneficios de la transformación digital en el sector financiero, desde la mejora de las experiencias de los clientes hasta la optimización de las operaciones. Nuestras soluciones digitales integrales no solo optimizan las operaciones empresariales, sino que también aseguran el cumplimiento, mejoran la seguridad y le preparan para un crecimiento escalable.",
                    "finance-benefit1": "<p><strong>Software Fintech para Consumidores:</strong> Nuestras soluciones digitales ofrecen a los consumidores una mayor visibilidad y control sobre sus operaciones financieras. Aprovechamos el poder de Fintech para agilizar los servicios de dinero y ofrecer experiencias digitales superiores.</p>",
                    "finance-benefit2": "<p><strong>Soluciones de Banca Digital:</strong> A medida que la banca digital gana popularidad, los clientes esperan una experiencia en línea que iguale o supere su experiencia en la sucursal. Alluxi crea soluciones digitales seguras para los bancos tradicionales para satisfacer y superar estas expectativas.</p>",
                    "finance-benefit3": "<p><strong>Herramientas de Inversión de Cartera:</strong> Desarrollamos herramientas digitales avanzadas para la gestión de carteras, permitiendo a la industria financiera aprovechar análisis y datos detallados para mejorar la toma de decisiones y realizar pronósticos precisos.</p>",
                    "finance-benefit4": "<p><strong>Cumplimiento Regulatorio:</strong> Nuestras soluciones aseguran que tu institución permanezca en cumplimiento con las regulaciones y estándares financieros mientras impulsa la innovación digital.</p>",
                    "finance-benefit5": "<p><strong>Seguridad:</strong> Priorizamos la seguridad en todas nuestras soluciones, garantizando la protección de la información financiera sensible y construyendo confianza con sus clientes.</p>",
                    "finance-benefit6": "<p><strong>Escalabilidad:</strong> Nuestras soluciones de software financiero están diseñadas para acomodar el crecimiento y adaptarse a los futuros avances tecnológicos.</p>",
                    education: "Educación",
                    "education-desc": "En el mundo de la educación, en rápida evolución, tanto los educadores como los alumnos deben adaptarse continuamente a las emergentes plataformas y metodologías en línea. El software educativo personalizable, escalable y efectivo se está convirtiendo cada vez más en la piedra angular de las instituciones académicas exitosas. En Alluxi, aprovechamos la tecnología moderna para construir tales soluciones, mejorando la entrega de educación y proporcionando una plataforma para el aprendizaje, en cualquier momento y lugar.",
                    "education-desc_title": "Servicios de transformación digital de Alluxi para el sector educativo",
                    "education-desc_subtitle": "Integramos tecnología y pedagogía para enriquecer las experiencias de aprendizaje",
                    "education-desc_text": "En Alluxi, aprovechamos nuestra amplia experiencia para ofrecer soluciones de desarrollo de primera calidad para el sector educativo. Al fusionar la tecnología de vanguardia con las mejores prácticas académicas, creamos poderosas soluciones digitales que impulsan el rendimiento, fomentan el crecimiento y mejoran los resultados. Estamos comprometidos a formar sólidas bases digitales para tu institución, garantizando transiciones suaves hacia este nuevo paisaje educativo.",
                    "education-help-title": "Nuestro Enfoque",
                    "education-help-desc": "Fusionamos innovación, experiencia e insight para desarrollar soluciones educativas transformadoras. Nuestra estrategia de lanzamiento detallada asegura una implementación exitosa y alineada con los estándares. Después del lanzamiento, ofrecemos soporte continuo incluyendo actualizaciones, solución de problemas y capacitación de usuarios, adaptándonos a medida que el panorama educativo evoluciona, garantizando el éxito duradero de nuestros productos.",
                    "education-design-title": "Diseño y Desarrollo",
                    "education-design-desc": "Creamos soluciones educativas influyentes, combinando creatividad y competencia técnica. Nuestro equipo se destaca en el desarrollo de aplicaciones de aprendizaje atractivas y sistemas eficientes de gestión de datos educativos. Comprendiendo las complejidades de la educación, diseñamos para la accesibilidad, el compromiso y la efectividad. Nuestro compromiso es mejorar las experiencias y resultados educativos a través de nuestro diseño y desarrollo perspicaz.",
                    "education-launch-title": "Lanzamiento ",
                    "education-launch-desc": "Nuestro enfoque al detalle asegura un lanzamiento exitoso de tu producto o servicio educativo. Garantizamos la alineación con los estándares educativos, la integración fluida en los sistemas existentes y un impacto inicial prometedor. Nuestra estrategia minimiza la interrupción maximizando las oportunidades de aprendizaje, preparando a tu equipo y usuarios para una transición sin problemas.",
                    "education-support-title": "Soporte",
                    "education-support-desc": "Después del lanzamiento, proporcionamos soporte continuo, supervisando las actualizaciones de software, la solución de problemas y la capacitación de usuarios. Monitorizamos el rendimiento de la solución, asegurando la alineación constante con las necesidades de los usuarios. A medida que el panorama educativo cambia, adaptamos nuestro soporte para asegurar el éxito a largo plazo de nuestras soluciones.",
                    "education-benefit-title": "Potenciar el aprendizaje y la enseñanza con Alluxi",
                    "education-benefit-desc": "Con Alluxi, los beneficios de la transformación digital en el sector educativo son multidimensionales. Nuestras soluciones no solo simplifican los procesos de enseñanza y aprendizaje, sino que también permiten la toma de decisiones basada en datos, garantizan el cumplimiento de las regulaciones y defienden la privacidad y la seguridad. Asóciese con nosotros para redefinir el viaje educativo.",
                    "education-benefit1": "<p><strong>Plataformas de E-Learning Personalizadas:</strong> Diseñamos y desarrollamos plataformas de e-learning a medida que respaldan su modelo académico y mejoran la transición digital desde los métodos tradicionales de educación.</p>",
                    "education-benefit2": "<p><strong>Software de Gestión del Aprendizaje:</strong> Nuestras soluciones ayudan a los educadores a gestionar el e-learning de manera efectiva, manteniendo a los estudiantes comprometidos con plataformas interactivas y fáciles de usar.</p>",
                    "education-benefit3": "<p><strong>Herramientas Digitales Personalizadas para Estudiantes:</strong> Reconocemos que cada estudiante tiene necesidades de aprendizaje únicas. Nuestras herramientas digitales personalizadas dan a los estudiantes la flexibilidad para aprender a su propio ritmo y mejorar la comunicación con los educadores.</p>",
                    "education-benefit4": "<p><strong>Análisis de Datos Educativos:</strong> Aproveche el poder de los datos para mejorar los resultados de enseñanza y aprendizaje. Nuestras soluciones incluyen tableros de análisis avanzados para el seguimiento y visualización del progreso de los estudiantes.</p>",
                    "education-benefit5": "<p><strong>Seguridad y Cumplimiento:</strong> Nos aseguramos de que todas nuestras soluciones de software cumplan con las regulaciones de la industria educativa y mantengan los más altos estándares de privacidad y seguridad de los datos.</p>",
                    "education-benefit6": "<p><strong>Escalabilidad:</strong> Nuestras soluciones están diseñadas para crecer con tu institución, acomodando un número creciente de usuarios y adaptándose a las tendencias educativas en evolución.</p>",
                    by: "POR",
                    "related-posts": "Artículos Relacionados",
                    "discover-more": "Descubre más",
                    "get-in-touch": "Contáctanos",
                    contact: "Contáctanos",
                    "tell-us-more": "Cuéntanos más de tu producto",
                    "contact-desc": "¡Queremos saber más acerca de tu proyecto! Déjenos tu información y cuéntenos un poco al respecto, nos pondremos en contacto contigo lo antes posible.",
                    name: "Nombre",
                    "enter-name": "Ingrese tu nombre",
                    email: "Correo",
                    "enter-email": "Ingrese tu correo",
                    "invalid-email": "Correo no válido",
                    "invalid-message": "Escribe un mensaje de al menos 10 caracteres",
                    "project-detail": "Detalles del proyecto",
                    "enter-project-detail": "Ingrese los detalles del proyecto",
                    message: "Mensaje",
                    "enter-message": "Ingresa tu mensaje",
                    "which-services": "¿En qué servicio estás interesado?",
                    "web-develop": "Desarrollo Web",
                    "mobile-develop": "Desarrollo Móvil",
                    send: "Enviar",
                    "thanks-contacting-us": "¡Gracias por contactarnos!\nHemos recibido tu mensaje, nos pondremos en contacto contigo lo antes posible.",
                    "error-contact-us": "¡Ups! Parece que hubo un error al enviar el correo electrónico.\nNuestros ingenieros lo revisarán en breve.",
                    "error-recaptcha": "Por favor acepta el captcha.",
                    ready: "¿Estás listo para tu siguiente proyecto?",
                    "ready-desc": "¡Cuéntanos sobre tus ideas para que podamos crear un producto exitoso juntos!",
                    "get-tips": "Recibe consejos, casos de estudio y noticias de Alluxi.",
                    "email-adress": "Correo electrónico",
                    sending: "Enviando...",
                    "thanks-sub": "Gracias por subscribirte",
                    "about-footer": "Nosotros",
                    "privacy-policy": "Política de Privacidad",
                    "pp-paragraph-1": `ALLUXI S.A DE C.V., (en adelante “ALLUXI”) es una sociedad constituida conforme a las Leyes de los Estados Unidos Mexicanos, con domicilio ubicado en América 327, Colonia Centro, C.P. 64000, Monterrey, Nuevo León, y estamos conscientes que usted como visitante de nuestras oficinas y sitio web consumidor o potencial consumidor de nuestros productos y/o servicios tiene derecho a conocer qué información recabamos de usted y nuestras prácticas en relación con dicha información.`,
                    "pp-paragraph-2": `Las condiciones contenidas en el presente son aplicables a la información que se recaba a nombre de y por ALLUXI o cualquiera de sus empresas filiales o subsidiarias, por cualquier medio, incluyendo a través de la página web ALLUXI o cualquier otro sitio operado por ALLUXI.`,
                    "pp-paragraph-3": `Los datos personales que puede llegar a recabar ALLUXI de forma directa o indirecta consisten en los siguientes: su nombre completo, dirección, teléfonos de casa, trabajo y celular, correo electrónico y ocupación, así como datos patrimoniales o financieros. Nos comprometemos a que todos los datos obtenidos serán tratados bajo las más estrictas medidas de seguridad que garanticen su confidencialidad.`,
                    "pp-paragraph-4": `ALLUXI informa que todos los contratos de prestación de servicios con terceros que impliquen el tratamiento de su información personal a nombre y por cuenta del ALLUXI incluirán una cláusula garantizando que otorgan el nivel de protección de datos personales. En cualquier caso, todo manejo de datos personales se realizará dando cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante la “Ley”) y su Reglamento.`,
                    "pp-paragraph-5": `La finalidad para la que recabamos sus datos tienen por objeto proveer servicios y productos requeridos, informar sobre nuevos productos, servicios o cambios en los mismos, mensajes promocionales y evaluar la calidad del servicio que le brindamos.`,
                    "pp-paragraph-6": `La información que proporcione deberá ser veraz y completa. Por lo que queda bajo su responsabilidad la veracidad de los datos proporcionados y en ningún caso ALLUXI será responsable a este respecto.`,
                    "pp-paragraph-7": `De manera adicional utilizaremos su información personal para las siguientes finalidades secundarias , que nos permiten y facilitan brindarle una mejor atención: por lo cual podremos notificarle promociones, ofertas y servicios a través de correo electrónico, este tipo de publicidad se realiza mediante avisos y mensajes promocionales de correo electrónico, los cuales sólo serán enviados a usted y a aquellos contactos registrados para tal propósito, esta indicación podrá modificarla en cualquier momento. En los correos electrónicos enviados, pueden incluirse ocasionalmente ofertas de terceras partes que sean nuestros socios comerciales.`,
                    "pp-paragraph-8": `De igual forma en el momento que lo estime oportuno podrá ejercer sus derechos ARCO (acceso, rectificación, cancelación y oposición) sobre el tratamiento de los datos personales proporcionados, así como revocar el consentimiento otorgado en este documento, por lo cual deberá ponerse en contacto con nosotros a través del correo electrónico hola@alluxi.com; el procedimiento y requisitos que deberá contener su solicitud de conformidad con lo dispuesto en la Ley y su Reglamento, son los siguientes:`,
                    "pp-paragraph-9": `(1) Nombre y Domicilio, si no se incluye la dirección se dará por no recibida la solicitud.
                    (2) El documento que acredite su identidad o la personalidad de su representante. (Copia de identificación oficial vigente.) El representante deberá acreditar la identidad del titular, identidad del representante, y sus facultades de representación mediante instrumento público o carta poder firmada ante dos testigos, o declaración en comparecencia personal del titular.
                    (3) La descripción clara y precisa de los datos personales a los que desea acceder, rectificar, cancelar u oponerse.
                    (4) Descripción de otros elementos que faciliten la localización de sus datos personales. (sitio Web, Sucursal.)`,
                    "pp-paragraph-10": `Los documentos deberán ser escaneados y adjuntados al correo electrónico para verificar la veracidad de los mismos.`,
                    "pp-paragraph-11": `Para conocer el procedimiento, requisitos y plazos del ejercicio de los derechos puedes ponerte en contacto al correo electrónico hola@alluxi.com.`,
                    "pp-paragraph-12": `Le informamos que en nuestras páginas de Internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página, así como ofrecerle nuevos productos y servicios basados en sus preferencias. Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: horario de navegación, tiempo de navegación en nuestra página de Internet, secciones consultadas, y páginas de Internet accedidas previo a la nuestra.`,
                    "pp-paragraph-13": `En el caso de empleo de cookies, el botón de “ayuda” que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirá cómo evitar aceptar nuevos cookies, cómo hacer que el navegador le notifique cuando recibe un nuevo cookie o cómo deshabilitar todos los cookies.`,
                    "pp-paragraph-14": `ALLUXI ha adoptado los niveles de seguridad de protección de datos personales legalmente requeridos por la Ley y su Reglamento, bajo los principios de licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad. Todo esto bajo estrictas reglas de seguridad, confidencialidad y consistencia de información.`,
                    "pp-paragraph-15": `Hacemos de su conocimiento que sus datos personales serán resguardados bajo estrictas medidas de seguridad administrativas, técnicas y físicas las cuales han sido implementadas con el objeto de proteger sus datos personales contra daño, perdida, alteración, destrucción o el uso o acceso o tratamiento no autorizado.`,
                    "pp-paragraph-16": `El sitio Web de ALLUXI conecta con ciertos links de otras páginas Web que pertenecen a terceros sobre los que ALLUXI no tiene control alguno. En estos supuestos ALLUXI no asume responsabilidad alguna ni compromiso sobre las políticas de privacidad de la información contenidas en esas páginas.`,
                    "pp-paragraph-17": `ALLUXI se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios y prácticas del mercado, cualquier modificación al Aviso de Privacidad estará disponible a través de nuestro portal Web ; sección “aviso de privacidad”.`,
                    "pp-paragraph-18": `Al navegar en cualquiera de los sitios Web de ALLUXI, significa que ha leído, entendido y esta de acuerdo con los términos antes expuestos.`,
                    "pp-paragraph-19": `El presente Aviso de Privacidad ha sido modificado el día 20 de Octubre de 2020.`,
                    follow: "Síguenos",
                    "page-not-found": "Ooops! Página no encontrada",
                    "page-not-found-desc": "La página que busca no existe o ha sido movida.",
                    "take-me-home": "Regresar al inicio",
                    "right-reserved": "Todos los derechos reservados.",
                    FAQs: "FAQs",
                    "faqs-title": "Preguntas Frecuentes",
                    "type-dev-offer": "¿Qué tipo de servicios ofrece Alluxi?",
                    "type-dev-offer-answer": `Ofrecemos una amplia gama de servicios de desarrollo de software, incluido el desarrollo de aplicaciones web y móviles, desarrollo de software a medida, pruebas de software y control de calidad, y consultoría y soporte de software.`,
                    "how-long": "¿Cuánto se tarda normalmente en completar un proyecto de desarrollo de software?",
                    "how-long-answer": `El plazo para completar un proyecto de desarrollo de software varía en función de la complejidad del proyecto y de los requisitos específicos del cliente. Para proyectos sencillos puede llevar de 1-3 meses, mientras que proyectos más complejos pueden tardar de 6 meses a 1 año o más.
                    Trabajamos en colaboración con cada cliente para establecer plazos e hitos realistas, y utilizamos metodologías ágiles de gestión de proyectos para garantizar que los proyectos se mantengan en el buen camino.`,
                    "manage-communication": "¿Cuál es su proceso para la gestión de proyectos y la comunicación con los clientes?",
                    "manage-communication-answer": `Utilizamos un enfoque de gestión de proyectos ágil, enfocado en la colaboración, la flexibilidad y el desarrollo iterativo. Utilizamos herramientas de gestión de proyectos como Jira y Asana para hacer un seguimiento del progreso y comunicación con los clientes regular por correo electrónico, teléfono y videoconferencia.`,
                    "past-example": "¿Pueden dar ejemplos de proyectos similares en los que hayan trabajado en el pasado?",
                    "past-example-answer": `Sí, hemos trabajado en una amplia gama de proyectos de desarrollo de software para clientes de diversos sectores, como sanidad, finanzas y comercio electrónico. Si lo deseas, podemos facilitarle estudios de casos y referencias o puedes visitar nuestros Casos de éxito en la barra de navegación.`,
                    costs: "¿Cuál es el modelo de precios de Alluxi y cómo se determinan los costes de un proyecto?",
                    "costs-answer": `Nuestro modelo de precios se basa en el alcance y la complejidad del proyecto, así como en el nivel de experiencia que requiere nuestro equipo de desarrollo. Proporcionamos presupuestos transparentes y detallados basados en una evaluación exhaustiva de las necesidades y requisitos del cliente.`,
                    testing: "¿Qué enfoque aplican a las pruebas y garantía de calidad durante el proceso de desarrollo de software?",
                    "testing-answer": `La garantía de calidad es una parte esencial de nuestro proceso de desarrollo de software. Utilizamos una serie de metodologías de prueba, como pruebas unitarias, pruebas de integración y pruebas de aceptación, para garantizar que nuestro software sea fiable, funcional y fácil de usar. También ofrecemos mantenimiento y asistencia continuos para garantizar que el software de nuestros clientes siga satisfaciendo sus necesidades a lo largo del tiempo.`,
                    "garre-challenge": "Siempre estamos buscando formas de ayudar a nuestros clientes a tener éxito. Recientemente, tuvimos la oportunidad de trabajar con un cliente en la industria de las aerolíneas que quería mejorar sus ventas de boletos en línea. Pudimos proporcionarles una aplicación web personalizada que facilitó a los clientes la búsqueda y compra de boletos de avión.",
                    "garre-solution": "La aplicación que creamos para nuestro cliente fue fácil de usar e intuitiva, permitiendo a los clientes encontrar rápidamente los vuelos que buscaban. La funcionalidad de búsqueda era sólida, permitiendo a los usuarios filtrar por destino, fecha de salida, número de pasajeros y otros criterios. La página de resultados estaba organizada y era fácil de navegar, lo que facilitaba a los usuarios comparar diferentes opciones de vuelo y elegir la que mejor se adaptara a sus necesidades.\nUna vez que un usuario había seleccionado un vuelo, podían completar el proceso de compra de manera rápida y segura. La aplicación aceptaba una variedad de opciones de pago, incluyendo tarjetas de crédito, tarjetas de débito y PayPal. También implementamos medidas de seguridad sólidas para proteger información sensible y prevenir fraudes.",
                    "garre-outcome": `<p>Una de las características clave de la aplicación fue su capacidad para mostrar <strong>información de vuelo en tiempo real</strong>. Esto significaba que los usuarios podían ver información actualizada sobre <strong>horarios de vuelo, retrasos y cancelaciones</strong>, lo que les permitía tomar decisiones informadas sobre sus planes de viaje.</p>
                    <p><strong>Nuestro cliente estaba extremadamente satisfecho</strong> con la aplicación web que desarrollamos para ellos. En el primer mes después de lanzar la aplicación, experimentaron un <strong>aumento significativo en las ventas de boletos en línea</strong>. La aplicación les ayudó a llegar a un <strong>público más amplio</strong> y proporcionó una forma conveniente y fácil de usar para que los clientes compraran boletos.</p>
                    <p>En general, pudimos entregar un <strong>producto de alta calidad</strong> que satisfizo las necesidades de nuestro cliente y superó sus expectativas. Estamos seguros de que nuestra aplicación web continuará ayudando a nuestro cliente a hacer <strong>crecer su negocio y mejorar su presencia en línea</strong>.</p>`,
                    "nativis-challenge": "Nativis Projex es una startup enfocada en la compra y venta de casas eco sostenibles, con un enfoque muy basado en la comunidad y el objetivo de hacer del país un planeta más verde.",
                    "nativis-solution": "Nativis solicitó nuestros servicios para construir la aplicación móvil Nativix Projex, una aplicación única para la gestión de alojamientos orientada a alojamientos con necesidades específicas. La app móvil debía ser capaz de ofrecer servicios de alquiler así como facilitar la compra de inmuebles. Para nuestro cliente era muy importante destacar el espíritu ecológico de este proyecto. Favorecer la comunicación a través de interacciones sociales entre usuarios.",
                    "nativis-outcome": `Alluxi sugirió una <strong>aplicación móvil híbrida</strong>, ya que nos permitiría lanzarla rápidamente para los usuarios de iOS y Android. Además, proporcionamos una herramienta de administración que permite al cliente supervisar y controlar todas las operaciones que ocurren en la aplicación. Actualmente, <strong>los usuarios pueden publicar sus propiedades</strong> en la aplicación, así como <strong>encontrar propiedades en alquiler</strong>. La plataforma soporta varios roles, así como la interacción de ideas a través de <strong>publicaciones</Strong>.`,
                    "sp-challenge": "Nuestro cliente buscaba crear una plataforma centrada en el fútbol que facilitara el descubrimiento de compañeros de juego y permitiera que los jugadores se emparejaran fácilmente. La aplicación debía encargarse de diversos aspectos, como la selección de campos, las calificaciones de los jugadores y un sistema de pago sin problemas para que los jugadores reciban sus pagos después de cada partido. Para garantizar el máximo alcance, la aplicación debía ser compatible tanto con dispositivos Android como iOS. Los desafíos principales eran crear una interfaz de usuario intuitiva, desarrollar un algoritmo de emparejamiento eficiente y garantizar un procesamiento de pagos seguro y confiable.",
                    "sp-solution": "Nuestro equipo desarrolló una aplicación móvil integral que abordó todos los requisitos del cliente. Las características clave de la aplicación incluían:",
                    "sp-solution1": "Perfiles de usuario: Los usuarios podían crear perfiles, ingresando su nivel de habilidad, posición preferida y disponibilidad, lo que permitía que la aplicación los emparejara de manera precisa con otros jugadores.",
                    "sp-solution2": "Selección de campo: La aplicación ofrecía una funcionalidad de mapa integrado que permitía a los usuarios seleccionar campos de fútbol cercanos, asegurando un acceso conveniente para todos los participantes.",
                    "sp-solution3": "Algoritmo de emparejamiento: Nuestros desarrolladores diseñaron un algoritmo sofisticado que consideraba diversos factores, como nivel de habilidad, posición, ubicación y disponibilidad para generar coincidencias adecuadas para los jugadores.",
                    "sp-solution4": "Calificaciones de los jugadores: Para mantener experiencias de alta calidad, la aplicación incorporó un sistema de revisión entre pares, permitiendo a los usuarios calificar a sus compañeros de juego después de cada partido.",
                    "sp-solution5": "Sistema de pago: Integré un sistema de pago seguro dentro de la aplicación que facilitó transacciones fluidas entre los jugadores, asegurando pagos rápidos después de los partidos.",
                    "sp-solution6": "Disponibilidad en múltiples plataformas: Para atender a una audiencia más amplia, desarrollamos aplicaciones nativas tanto para Android como para iOS, garantizando una funcionalidad perfecta en cada una.",
                    "sp-outcome": `<p>La aplicación de mercado de <strong>encuentro de jugadores de fútbol</strong> se lanzó con éxito en las plataformas Android e iOS, recibiendo elogios generalizados de los usuarios. La aplicación <strong>ha simplificado significativamente el proceso de encontrar partidos de fútbol</strong>, mejorando la <strong>accesibilidad y la comodidad para jugadores de diversos niveles</strong> de habilidad. Los resultados clave del proyecto incluyen:<p>
                    <p><strong>Más de 5,000 descargas</strong> en los primeros tres meses de lanzamiento, con una <strong>calificación constante de 4.5 estrellas</strong> tanto en la tienda de aplicaciones Android como en la de iOS. <strong>Más de 1,000 usuarios activos</strong> que participan en partidos de fútbol semanalmente, con un <strong>95%</strong> de usuarios que informan satisfacción con las funciones de emparejamiento de la aplicación. Un <strong>aumento del 30%</strong> en la frecuencia de partidos de fútbol jugados entre los usuarios, demostrando la efectividad de la aplicación para <strong>fomentar una comunidad de fútbol más activa y comprometida</strong>.</p>`,
                    "dogit-challenge": "Nuestro cliente quería crear una solución integral para que los dueños de mascotas accedieran a servicios veterinarios, mantuvieran registros de salud de sus mascotas y compraran productos relacionados con las mascotas. La aplicación debía ser informativa, fácil de usar y estar disponible tanto en iOS como en Android. Los principales desafíos incluían integrar varios servicios en una sola plataforma, crear una interfaz de usuario intuitiva y garantizar transacciones seguras y confiables para productos y servicios.",
                    "dogit-solution": "Para abordar los requisitos del cliente, desarrollamos la aplicación DogIT con las siguientes características clave:",
                    "dogit-solution1": "Mercado de servicios veterinarios: Los usuarios pueden acceder a una amplia gama de servicios veterinarios, como aseo, vacunación y desparasitación, proporcionados por profesionales locales. La aplicación permite a los usuarios reservar citas y pagar los servicios de manera segura dentro de la plataforma.",
                    "dogit-solution2": "Registros de salud y recordatorios: La aplicación permite a los usuarios mantener un registro de salud completo para sus mascotas, incluido el historial de vacunación y los programas de desparasitación. Además, la aplicación envía recordatorios oportunos para las próximas vacunas y chequeos.",
                    "dogit-solution3": "Tienda de productos para mascotas: Integramos una plataforma de comercio electrónico dentro de la aplicación, lo que permite a los usuarios explorar y comprar productos relacionados con las mascotas, como alimentos, juguetes y accesorios, de proveedores confiables. Información de razas y consejos para el cuidado: La aplicación incluye una biblioteca de información detallada sobre diversas razas de perros, incluido su temperamento, requisitos de cuidado y posibles problemas de salud, para ayudar a los usuarios a comprender y cuidar mejor a sus mascotas.",
                    "dogit-solution4": "Breed Information & Care Tips: The app includes a library of detailed information about various dog breeds, including their temperament, care requirements, and potential health issues, to help users better understand and care for their pets.",
                    "dogit-solution5": "Disponibilidad en varias plataformas: Desarrollamos aplicaciones nativas tanto para dispositivos Android como iOS para atender a una audiencia más amplia y garantizar una funcionalidad perfecta en cada plataforma.",
                    "dogit-outcome": `<p>La aplicación <strong>DogIT</strong> se lanzó con éxito en las plataformas Android e iOS, obteniendo comentarios positivos tanto de los dueños de mascotas como de los veterinarios. La aplicación se ha convertido en un valioso recurso para los usuarios, simplificando la gestión del cuidado de las mascotas y facilitando el acceso a servicios veterinarios. Los principales resultados del proyecto incluyen:</p>
                    <p><strong>Más de 7,000 descargas</strong> en los primeros tres meses desde el lanzamiento, con una <strong>calificación promedio de 4.6 estrellas</strong> tanto en las tiendas de aplicaciones Android como iOS. Una creciente red de <strong>más de 150 veterinarios</strong> locales que ofrecen servicios a través de la aplicación, lo que brinda a los usuarios una amplia variedad de opciones para elegir. <strong> Más de 1,500 productos </strong> para mascotas vendidos en el primer trimestre, con un <strong>99%</strong> de las transacciones procesadas de manera segura y eficiente. Un <strong>aumento del 40%</strong> en las citas veterinarias programadas, vacunas y servicios de aseo entre los usuarios de la aplicación, lo que demuestra la efectividad de la plataforma para promover el cuidado proactivo de las mascotas. <strong>Más del 90% de los usuarios</strong> informaron un mayor conocimiento sobre la raza de su perro y sus requisitos</p>`,
                    "flux-challenge": "Flux, una compañía de alquiler de vehículos eléctricos con sede en Austin, buscaba una forma práctica de trasladar su servicio de alquiler de coches físico a una aplicación móvil. Necesitaban una plataforma fácil de usar para sus clientes y una forma sencilla de gestionar su flota de 100 vehículos.",
                    "flux-solution": "Alluxi desarrolló una aplicación móvil adaptada a las necesidades de Flux. Esta aplicación no se trata solo de alquiler de coches, sino que va un paso más allá. Ayuda a los clientes a encontrar estaciones eléctricas cercanas y les muestra los vehículos Flux disponibles en cada ubicación. La aplicación se enlaza con el sistema ERP existente de Flux, Odoo, permitiendo actualizaciones en tiempo real de la disponibilidad de vehículos. Esta solución ha ayudado a Flux a agilizar su servicio y garantizar que los clientes tengan información precisa a su alcance.",
                    "flux-outcome": "La aplicación ha revolucionado la forma en que Flux opera e interactúa con sus clientes. Con información actualizada de los vehículos, los clientes pueden alquilar un vehículo eléctrico con facilidad, y Flux puede gestionar su flota de manera más eficiente. Es una solución en la que todos ganan, que ha mejorado los servicios de Flux y ha elevado la experiencia del usuario.",
                    "crushed-challenge": 'Nuestro cliente imaginó una aplicación social atractiva, "Crushed!?", Que iba más allá de las aplicaciones de citas convencionales. Su idea era crear una plataforma que pudiera ayudar a las personas a reconectar con sus antiguos amores y también fomentar nuevas relaciones.',
                    "crushed-solution": 'Alluxi aceptó el desafío diseñamos y desarrollamos "Crushed!?", Una aplicación social única que te ayuda a encontrar a tu amor perdido hace mucho tiempo basándose en la ubicación y las circunstancias del encuentro pasado. Sin embargo, la aplicación no se detiene en reavivar viejas llamas. También fomenta nuevas conexiones, permitiendo a los usuarios coincidir con nuevas personas y generar nuevas amistades o romances. Diseñado tanto para iOS como para Android, "Crushed!?" es una solución versátil para aquellos que buscan reconectar o establecer nuevas conexiones.',
                    "crushed-outcome": 'Crushed!?" no es solo una aplicación, es un puente al pasado y una puerta a futuras conexiones. Con sus funcionalidades únicas, ha podido ayudar a los usuarios a encontrar amores perdidos hace mucho tiempo y cultivar nuevas relaciones. La visión de nuestro cliente se hizo realidad con éxito, y ahora "Crushed!?" está cambiando la forma en que las personas se conectan, un romance a la vez.',
                    "haig-challenge": 'La startup "Howamigoing" tenía como objetivo revolucionar la retroalimentación de los empleados con su aplicación. Si bien tenían una base sólida, necesitaban ayuda experta para diseñar y desarrollar nuevas funcionalidades básicas, administrar su infraestructura en Google Cloud Platform, desarrollar nuevos puntos finales GraphQL y resolver errores críticos.',
                    "haig-solution": 'La startup "Howamigoing" tenía como objetivo revolucionar la retroalimentación de los empleados con su aplicación. Si bien tenían una base sólida, necesitaban ayuda experta para diseñar y desarrollar nuevas funcionalidades básicas, administrar su infraestructura en Google Cloud Platform, desarrollar nuevos puntos finales GraphQL y resolver errores críticos.',
                    "haig-outcome": 'Alluxi unió fuerzas con "Howamigoing". En lugar de comenzar desde cero, nos integramos con su equipo para apoyar sus esfuerzos en curso. Gestionamos su infraestructura en Google Cloud Platform, garantizando el funcionamiento suave de su aplicación. Desarrollamos nuevos puntos finales GraphQL para mejorar la eficiencia y velocidad de las solicitudes de datos. Nuestro equipo también diseñó y desarrolló nuevos módulos de software que mejoraron la experiencia del usuario. A lo largo del proceso, identificamos y resolvimos errores críticos para el negocio, asegurando el rendimiento óptimo de la aplicación.',
                    "digitab-challenge": "Dijimos adiós a los recibos impresos.",
                    "digitab-solution": "Digitab es una startup enfocada en reducir el uso de papel en restaurantes y tiendas minoristas.",
                    "digitab-outcome": "Diseñamos, desarrollamos y lanzamos una aplicación móvil híbrida que permite a los meseros generar recibos digitalmente y permite a los usuarios escanear estos recibos digitales y guardarlos en su cuenta. Adicionalmente, desarrollamos una integración con POS al crear una impresora digital que envía los datos de los recibos directamente a un servicio en la plataforma de Digitab. De esta manera, los usuarios pueden guardar sus recibos digitalmente.",
                    "zplendid-challenge": "Nuestro cliente, una reconocida Clínica Bariátrica, estaba lidiando con una diversidad de desafíos. Gestionar una creciente comunidad de 3000 pacientes que habían pasado o se estaban preparando para la cirugía bariátrica requería una cantidad significativa de recursos y organización. Había una necesidad urgente de simplificar las interacciones entre pacientes y médicos, seguir el progreso de manera efectiva y mantener registros médicos precisos. Además, era esencial evaluar la elegibilidad de los posibles pacientes para la cirugía, gestionar la programación de citas y los arreglos de viaje, e integrarse con dispositivos de báscula en el hogar.",
                    "zplendid-solution": "Nuestro cliente, una reconocida Clínica Bariátrica, estaba lidiando con una diversidad de desafíos. Gestionar una creciente comunidad de 3000 pacientes que habían pasado o se estaban preparando para la cirugía bariátrica requería una cantidad significativa de recursos y organización. Había una necesidad urgente de simplificar las interacciones entre pacientes y médicos, seguir el progreso de manera efectiva y mantener registros médicos precisos. Además, era esencial evaluar la elegibilidad de los posibles pacientes para la cirugía, gestionar la programación de citas y los arreglos de viaje, e integrarse con dispositivos de báscula en el hogar.",
                    "zplendid-outcome": "El desarrollo de Zplendid duró un año desde el análisis y diseño hasta las pruebas y el lanzamiento. Hoy en día, la aplicación sirve como un sistema centralizado que gestiona con éxito una comunidad de 3000 pacientes, reduciendo en gran medida los recursos necesarios y mejorando la calidad de la atención al paciente. La aplicación ha sido bien recibida en ambas plataformas, Android e iOS.",
                    "posco-challenge": "POSCO, una destacada empresa de fabricación de acero surcoreana, buscaba comprometer a su fuerza laboral en México de manera más efectiva. Con un equipo de 1,000 empleados, fomentar la interacción y comunicación, así como recompensar a los empleados por su arduo trabajo, era una tarea compleja.",
                    "posco-solution": "Alluxi fue encargado con este desafío y desarrolló la aplicación POSCO U&I. Esta plataforma innovadora no sólo permitió a la empresa recompensar a sus empleados generando puntos a partir de diversas actividades de la empresa, sino que también introdujo un componente social para potenciar la interacción entre los empleados.\n La aplicación incluía una tienda con una amplia gama de productos donde los empleados podían intercambiar sus puntos. La aplicación web permitió una gestión dinámica y la oferta de varias opciones de productos a través de un panel de administración.",
                    "posco-outcome": "La aplicación móvil POSCO U&I fue bien recibida por los empleados. La plataforma aumentó efectivamente el compromiso y fomentó un sentido de comunidad dentro de la empresa. Además, condujo a una reducción significativa en la rotación de empleados, validando el impacto de la solución.",
                    thankyou: "Gracias",
                    "thankyou-sub": "Gracias por tu solicitud",
                    "thankyou-desc": "¡Nos pondremos en contacto contigo tan pronto como sea posible!",
                    "back-home": "Volver a la página de inicio",
                },
            },
        },
        fallbackLng: "en",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;