import BlogSuggestions from "./BlogSuggestions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BlogMain() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div>
      <div className="relative pt-16 pt-16  overflow-hidden m-auto bg-white ">
        <div className="m-auto mx-4 top-0 xl:w-full text-center">
          <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
            BLOG
          </span>
          <h2 className="text-5xl xl:text-5xl lg:text-5xl md:text-4xl text-nblue-900 font-bold">
            {t("blog-title")}
          </h2>
          <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
            {t("blog-desc")}
          </p>
        </div>

        <div className="relative mt-16 mx-4 xl:mx-16">
          <BlogSuggestions></BlogSuggestions>

          <Link
            to={currentLanguage === "en" ? "/blog" : "/es/blog"}
            className="justify-center w-64 h-14 px-[39px] py-[12px] border rounded-[28px] shadow-sm text-[14px] font-manrope m-auto text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow hover:from-agray-500 hover:to-agray-500 flex mt-16 items-center"
          >
            <label>{t("see-all-post")}</label>
          </Link>
        </div>
      </div>
    </div>
  );
}
