import { Link, useLocation } from 'react-router-dom';

import { CheckIcon } from '@heroicons/react/outline';
import ContactUs from './ContactUs';
import FAQs from './FAQs';
import { Helmet } from 'react-helmet';
import Ready from './Ready';
import WeHaveItAll2 from './WeHaveItAll2';
import WhyChooseUs from './WhyChooseUs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ServiceUXHero(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get('q');

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <>
      <Helmet>
        <title> {t('user-experience')} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t('ux/ui-desc')} />
        <meta property="og:description" content={t('ux/ui-desc')} />
        <meta
          property="og:image"
          content={require('../assets/img/services/hero-ux.png')}
        />
        <meta property="og:title" content={t('user-experience')} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <main className={`bg-agray-100 pt-40 `}>
        <div className="md:max-w-3xl lg:max-w-5xl xl:max-w-7xl md:grid  md:grid-cols-2 pb-10 mx-auto">
          <div className="my-auto tv:px-0  md:order-2 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px]">
            <img
              src={require('../assets/img/services/hero-ux.png')}
              alt="hero_img"
            />
          </div>
          <div className="text-left w-full">
            <div className="pb-4">
              <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t('ux/ui-title')}
              </span>
            </div>
            <h1 className="text-5xl text-nblue-900 font-bold">
              {t('ux/ui-subtitle')}
            </h1>
            <div className="mt-9">
              <Link
                to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
                className="hover:bg-nblue-600 bg-ablue-600 md:w-72 flex items-center justify-center px-[22px] py-[17px]  text-sm font-manrope rounded-[28px] text-white"
              >
                <label>{t('next-project')}</label>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-white pt-16 pb-16 m-auto">
          <div className="tv:max-w-none tv:px-[336px] lg:px-36 px-auto px-4 ">
            <div className="relative overflow-hidden pb-[232px]">
              <div className="mt-24 md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-6 items-center">
                <div className="md:order-2">
                  <div className="mt-6">
                    <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t('ux/ui-desc-title')}
                    </span>
                    <h2 className="text-5xl text-nblue-900 font-bold">
                      {t('ux/ui-desc-subtitle')}
                    </h2>
                    <p className="font-light mt-4 text-lg text-agray-500 m-auto">
                      {t('ux/ui-desc')}
                    </p>
                  </div>
                </div>
                <img
                  className="mt-12 md:mt-0 my-auto w-[357.9] h-[404px] lg:w-[489px] lg:h-[553px]"
                  src={require('../assets/img/services/uxdesc.png')}
                  alt="ux_desc"
                />
              </div>
            </div>
            <div className="relative pb-[127px] overflow-hidden m-auto">
              <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense gap-4">
                <div>
                  <div className="">
                    <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t('ux/ui-benefit-title')}
                    </span>
                    <h2 className="text-5xl text-nblue-900 font-bold mt-2">
                      {t('ux/ui-benefit-subtitle')}
                    </h2>
                    <div className="mt-12">
                      <div className="flex gap-[8px]">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('ux/ui-benefit1')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('ux/ui-benefit2')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('ux/ui-benefit3')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('ux/ui-benefit4')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('ux/ui-benefit5')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('ux/ui-benefit6')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="max-w-80 mx-auto my-8 mt-9 md:mt-0 my-auto w-[357.9] h-[404px] lg:w-[489px] lg:h-[553px]"
                  src={require('../assets/img/services/ux-benefits.png')}
                  alt="ux_benefits"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 mb-16 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px] mx-auto">
          <div className="pt-16 text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t('tools')}
            </span>
            <h2 className="text-5xl text-nblue-900 font-bold">
              {t('our-teck-stack')}
            </h2>
            <p className="font-light mt-4  text-lg text-agray-500 w-4/5 xl:w-1/2 m-auto  ">
              {t('our-teck-desc')}
            </p>
          </div>

          <div className="py-10">
            <div className="mt-6 grid grid-cols-2 md:grid-cols-4 pb-[113px]">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-figma.png')}
                    alt="Figma"
                  />
                  <p className=" text-sm text-agray-500  text-center">Figma</p>
                </div>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-sketch.png')}
                    alt="Sketch"
                  />
                  <p className=" text-sm text-agray-500  text-center">Sketch</p>
                </div>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-invision.png')}
                    alt="Invision"
                  />
                  <p className=" text-sm text-agray-500  text-center">
                    Invision
                  </p>
                </div>
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-zeplin.png')}
                    alt="Zeplin"
                  />
                  <p className=" text-sm text-agray-500  text-center">Zeplin</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {qParam === 'landing' && <FAQs></FAQs>}
        <WeHaveItAll2></WeHaveItAll2>
      </main>
      {qParam === 'landing' && <WhyChooseUs></WhyChooseUs>}
      {qParam === 'landing' ? <ContactUs></ContactUs> : <Ready></Ready>}
    </>
  );
}
