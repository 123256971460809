import { Fragment, useCallback, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BlogWidget from "./BlogWidget";

export default function BlogSuggestions() {
  let { slug } = useParams();
  const [relatedBlog, setRelatedBlog] = useState([]);
  const { i18n } = useTranslation();

  let items = [...new Map(relatedBlog.map((item) => [item.id, item])).values()];
  items = items.slice(0, 3);
  const cols = items.length;

  const fetchBlogData = useCallback(
    async (tag) => {
      fetch(
        `${process.env.REACT_APP_URL}?key=${process.env.REACT_APP_KEY}&filter=tag:${tag}`
      )
        .then(async (response) => {
          const data = await response.json();
          var transformedBlog = data.posts.map((blogData) => {
            return {
              id: blogData.uuid,
              slug: blogData.slug,
              title: blogData.title,
              description: blogData.excerpt.replace(/\n/g, ""),
              image: blogData.feature_image,
            };
          });
          let results = [...relatedBlog, ...transformedBlog];
          results = results.filter(function (element) {
            return element !== undefined && element.slug !== slug;
          });
          setRelatedBlog((relatedBlog) => results);
        })
        .catch(() => {
          throw new Error("Something went wrong");
        });
    },
    [relatedBlog, slug]
  );

  useEffect(() => {
    if (i18n.language === "es-ES" || i18n.language === "es") {
      fetchBlogData("es");
    } else {
      fetchBlogData("en");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Fragment>
      <div
        className={`hidden md:block ${
          cols === 1
            ? "md:m-auto md:w-1/2 "
            : cols === 2
            ? "md:grid md:grid-cols-2 md:grid-flow-col-dense xl:gap-8 xl:w-10/12 md:mx-auto md:max-w-7xl"
            : "md:grid md:grid-cols-3 md:grid-flow-col-dense md:mx-auto md:max-w-7xl md:px-8 md:gap-6"
        }`}
      >
        {items.length !== 0 ? (
          items.map((blog, index) => (
            <Link to={`/blog/detail/${blog.slug}`} key={index}>
              <div className="col-span-1 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
                <img
                  src={
                    blog.image
                      ? blog.image
                      : require("../../assets/img/blog-default.png")
                  }
                  className="mt-8 h-52  rounded-xl m-auto"
                  alt="related-post"
                ></img>
                <div className="mt-6 text-center">
                  <h3 className="text-2xl text-nblue-900 font-bold line-clamp-2">
                    {blog.title}
                  </h3>
                  <p className="font-light mt-4  text-lg text-agray-500 line-clamp-2">
                    {blog.description}
                  </p>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p className="font-light mt-4  text-xl text-agray-500 xl:col-span-3 lg:col-span-3 text-center ">
            No posts found
          </p>
        )}
      </div>
      <div className="md:hidden w-full flex justify-center">
        <BlogWidget></BlogWidget>
      </div>
    </Fragment>
  );
}
