import React from 'react';

const PlayButton = ({ onClick }) => {
  return (
    <div className='flex items-center self-center mr-auto ml-auto'>
      <button
        className="relative w-20 h-20 rounded-full bg-red-500 hover:scale-110 transition-transform duration-300 animate-pulse"
        onClick={onClick}
      >
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-ablue-500 text-4xl">
          ▶
        </span>
      </button>
    </div>
  );
};

export default PlayButton;