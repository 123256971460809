import { Fragment, useEffect, useRef } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react';

import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import education from '../assets/img/technology/services-education-color.svg';
import finance from '../assets/img/technology/services-finance-color.svg';
import healthcare from '../assets/img/technology/services-healthcare-color.svg';
import logo from '../assets/img/logo.svg';
import { useTranslation } from 'react-i18next';

//import { useNavigate } from "react-router-dom";
//
//import { Swiper, SwiperSlide } from "swiper/react";
//import SwiperCore, { Navigation, Autoplay } from "swiper";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar(noLinks=false) {
  // SwiperCore.use([Navigation, Autoplay]);

  // const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const buttonRef = useRef();
  const closeNav = useRef();
  const currentLanguage = i18n.language;

  const solutions = [
    {
      name: t('our-work'),
      href: currentLanguage === 'en' ? '/our-work' : '/es/our-work',
    },
    {
      name: t('about'),
      href: currentLanguage === 'en' ? '/about-us' : '/es/about-us',
    },
    {
      name: t('careers'),
      href: currentLanguage === 'en' ? '/careers' : '/es/careers',
    },
    {
      name: 'Blog',
      href: currentLanguage === 'en' ? '/blog' : '/es/blog',
    },
  ];
  /*
  const services = [
    {
      title: t("custom-web-develop"),
      description: t("custom-web-develop-desc"),
      url: "/services/custom-web-development",
      image: require("../assets/img/home/services-web-color.png"),
      icon: (
        <DesktopComputerIcon className="float-bottom text-ablue-900 w-8 h-8 rounded-full"></DesktopComputerIcon>
      ),
    },
    {
      title: t("custom-mobile-develop"),
      description: t("custom-mobile-develop-desc"),
      url: "/services/custom-mobile-development",
      image: require("../assets/img/home/services-mobile-color.png"),
      icon: (
        <DeviceMobileIcon className="float-bottom text-ablue-900 w-8 h-8 rounded-full"></DeviceMobileIcon>
      ),
    },
    {
      title: t("nearshore-service"),
      description: t("nearshore-service-desc"),
      url: "/services/nearshore-development",
      image: require("../assets/img/home/services-nearshore-color.png"),
      icon: (
        <GlobeIcon className="float-bottom text-ablue-900 w-8 h-8 rounded-full"></GlobeIcon>
      ),
    },
  ];*/

  const subnavbar = [
    {
      title: t('what-we-do'),
      options: [
        {
          title: t('custom-web-develop'),
          icon: require('../assets/img/home/services-web-color.png'),
          url:
            currentLanguage === 'en'
              ? '/services/custom-web-development'
              : '/es/services/custom-web-development',
        },
        {
          title: t('custom-mobile-develop'),
          icon: require('../assets/img/home/services-mobile-color.png'),
          url:
            currentLanguage === 'en'
              ? '/services/custom-mobile-development'
              : '/es/services/custom-mobile-development',
        },
        {
          title: t('nearshore-service'),
          icon: require('../assets/img/home/services-nearshore-color.png'),
          url:
            currentLanguage === 'en'
              ? '/services/nearshore-development'
              : '/es/services/nearshore-development',
        },
      ],
    },
    {
      title: t('services'),
      options: [
        {
          title: t('cloud'),
          icon: require('../assets/img/services/services-cloud-color.png'),
          url:
            currentLanguage === 'en'
              ? '/services/cloud-services'
              : '/es/services/cloud-services',
        },
        {
          title: t('ai-integration'),
          icon: require('../assets/img/services/services-ai-color.png'),
          url:
            currentLanguage === 'en'
              ? '/services/ai-integration-services'
              : '/es/services/ai-integration-services',
        },
        {
          title: t('user-experience'),
          icon: require('../assets/img/services/services-ux-color.png'),
          url:
            currentLanguage === 'en'
              ? '/services/ux-ui-services'
              : '/es/services/ux-ui-services',
        },
        {
          title: t('analytics'),
          icon: require('../assets/img/services/services-analytics-color.png'),
          url:
            currentLanguage === 'en'
              ? '/services/analytics-services'
              : 'es/services/analytics-services',
        },
      ],
    },
    {
      title: t('industries'),
      options: [
        {
          title: t('health-care'),
          icon: healthcare,
          url:
            currentLanguage === 'en'
              ? '/industry/healthcare'
              : '/es/industry/healthcare',
        },
        {
          title: t('finance'),
          icon: finance,
          url:
            currentLanguage === 'en'
              ? '/industry/finance'
              : '/es/industry/finance',
        },
        {
          title: t('education'),
          icon: education,
          url:
            currentLanguage === 'en'
              ? '/industry/education'
              : '/es/industry/education',
        },
      ],
    },
  ];

  const technologies = [
    {
      title: 'React',
      icon: require('../assets/img/services/tool-react.png'),
      url: '/technology/react-software-development',
    },
    {
      title: 'Python',
      icon: require('../assets/img/services/tool-python.png'),
      url: '/technology/python-software-development',
    },
    {
      title: 'AWS',
      icon: require('../assets/img/services/tool-aws.png'),
      url: '/technology/aws',
    },
    {
      title: 'Javascript',
      icon: require('../assets/img/services/tool-js.png'),
      url: '/technology/javascript-software-development',
    },
    {
      title: 'Ionic',
      icon: require('../assets/img/services/tool-ionic.png'),
      url: '/technology/ionic-software-development',
    },
  ];

  const changeLanguage = (lng) => {
    if (lng === 'es') {
      const currentUrl = window.location.href;
      const updatedUrl = currentUrl.includes('/es')
        ? currentUrl
        : currentUrl.replace(
            window.location.origin,
            `${window.location.origin}/es`
          );
      window.history.replaceState({}, document.title, updatedUrl);
    } else if (lng === 'en') {
      const currentUrl = window.location.href;
      const updatedUrl = currentUrl.replace('/es', '');
      window.history.replaceState({}, document.title, updatedUrl);
    }

    i18n.changeLanguage(lng);
  };

  const handleScroll = () => {
    window.onscroll = function () {
      scroll();
    };
    var barr = document.getElementById('id-barNav');

    function scroll() {
      //var height 		= document.body.scrollTop
      var height = document.documentElement.scrollTop;

      if (height > 80) {
        barr.classList.add('sty-logo');
      } else {
        barr.classList.remove('sty-logo');
      }
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <Popover
      className="fixed z-50 bg-white lg:bg-transparent w-full"
      id="id-barNav"
    >
      <nav className="fixed w-full z-20 top-0 left-0 bg-transparent">
        <div className="bg-white lg:bg-transparent max-w-screen-xl lg:grid lg:grid-cols-12 flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to={currentLanguage === 'en' ? '/' : '/es'}
            className="col-span-2"
          >
            <img
              src={logo}
              alt="Alluxi"
              className="logo_alluxi w-32 h-11 lg:h-[70px] lg:w-40"
            />
          </Link>
          <div className="flex lg:order-2 col-span-3 ">
            <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-full">
              <button
                onClick={() => changeLanguage('en')}
                className="text-ablue-900 whitespace-nowrap text-base font-bold text-agray-500 hover:text-ablue-500 mr-2"
              >
                EN{' '}
              </button>
              <p className="whitespace-nowrap text-base font-bold text-agray-500 hover:text-agray-900">
                {' '}
                I{' '}
              </p>
              <button
                onClick={() => changeLanguage('es')}
                className="text-ablue-900 whitespace-nowrap text-base font-bold text-agray-500 hover:text-ablue-500 ml-2"
              >
                ES{' '}
              </button>
            </div>
            <Link
              to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
              className="hidden lg:block  ml-8 whitespace-nowrap inline-flex items-center justify-center px-[39px] py-[12px] border rounded-[28px] shadow-sm text-[14px] font-manrope text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow
              hover:from-agray-500 hover:to-agray-500"
            >
              <label>{t('get-in-touch')}</label>
            </Link>
            <div className="-mr-2 -my-2 lg:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-ablue-400 hover:text-ablue-500 hover:bg-agray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ablue-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div
            className="ml-10 col-span-6 xl:col-span-7 flex flex-col items-center justify-center hidden w-full lg:flex lg:w-auto lg:order-1"
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 lg:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0">
              <li>
                <Popover.Group as="nav" className="hidden lg:flex space-x-10">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          ref={buttonRef}
                          className={classNames(
                            open ? 'text-nblue-500' : 'text-nblue-500',
                            'group rounded-md inline-flex items-center text-base font-bold hover:text-ablue-500'
                          )}
                        >
                          <span className="text-ablue-900 hover:text-ablue-500 focus:text-ablue-500">
                            {t('services')}
                          </span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-ablue-600' : 'text-ablue-400',
                              'ml-2 h-5 w-5 group-hover:text-ablue-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel>
                            <div className="absolute translate-x-[71px] mt-1 z-50 border-solid border-white z-1 border-b-8 border-x-transparent z-50 border-x-8 border-t-0"></div>
                            <div className="absolute z-10 -ml-9 mt-3 transform w-screen max-w-md lg:max-w-4xl ml-11 lg:translate-x-1">
                              <div className="rounded-3xl shadow-3xl ring-0 overflow-hidden ">
                                <div className="bg-white px-7 ">
                                  <div className="flex justify-between items-center ">
                                    <div className="flex "></div>
                                    <Popover.Button
                                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-agray-500 hover:text-agray-500 hover:bg-agray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ablue-500"
                                      ref={closeNav}
                                    >
                                      <span className="sr-only">
                                        Close menu
                                      </span>
                                      <XIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </Popover.Button>
                                  </div>
                                </div>
                                <div className="relative grid bg-white pb-6 gap-4 px-8 grid-cols-3">
                                  {subnavbar.map((item, index) => (
                                    <div
                                      className="px-4 max-w-xl mx-auto h-full lg:max-w-none lg:mx-0 lg:px-0"
                                      key={index}
                                    >
                                      <div className="h-full w-full">
                                        <div className="col-span-3">
                                          <h2 className="text-lg text-nblue-900 font-bold pb-6">
                                            {t(item.title)}
                                          </h2>
                                          {item.options.map((menu, index) => (
                                            <Link
                                              to={menu.url}
                                              key={index}
                                              onClick={() =>
                                                closeNav.current?.click()
                                              }
                                            >
                                              <div className="flex space-x-4 pb-8">
                                                <img
                                                  className="h-10 w-[51px]"
                                                  src={menu.icon}
                                                  alt={menu.title}
                                                />
                                                <h2
                                                  className={`text-[15px] py-3  my-auto font-serif ${
                                                    window.location.pathname !==
                                                    menu.url
                                                      ? 'text-agray-500 hover:text-ablue-500'
                                                      : 'text-ablue-500'
                                                  }`}
                                                >
                                                  {' '}
                                                  {menu.title}
                                                </h2>
                                              </div>
                                            </Link>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="bg-agray-100 px-8 rounded-[30px] col-span-3">
                                    <h2 className="text-lg text-nblue-900 font-bold mt-8">
                                      {t('technologies')}
                                    </h2>
                                    <div className="grid grid-cols-5 mt-6">
                                      {technologies.map((tech, index) => (
                                        <Link
                                          to={tech.url}
                                          key={index}
                                          onClick={() =>
                                            closeNav.current?.click()
                                          }
                                        >
                                          <div className="flex space-x-1 pb-8 col-span-1">
                                            <img
                                              className="h-10 w-[51px]"
                                              src={tech.icon}
                                              alt={tech.title}
                                            />
                                            <h2 className="text-[15px] text-agray-500 hover:text-ablue-500 my-auto font-serif">
                                              {' '}
                                              {tech.title}
                                            </h2>
                                          </div>
                                        </Link>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </Popover.Group>
              </li>
              {solutions.map((item) => (
                <li key={item.name} className="text-center xl:pl-8">
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => closeNav.current?.click()}
                    className="-m-3 py-3 flex items-start rounded-lg"
                  >
                    <p
                      className={`text-base font-bold ${
                        window.location.pathname !== item.href
                          ? 'text-ablue-900 hover:text-ablue-500 focus:text-ablue-500'
                          : 'text-ablue-500'
                      }`}
                    >
                      {item.name}
                    </p>
                  </Link>
                </li>
              ))}
              <li></li>
            </ul>
          </div>
        </div>
      </nav>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden h-screen overflow-y-scroll"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-agray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Link to={currentLanguage === 'en' ? '/' : '/es'}>
                    <img className="h-8 m-auto" src={logo} alt="Alluxi" />
                  </Link>
                </div>

                <div className="-mr-2">
                  <Popover.Button
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-agray-500 hover:text-agray-500 hover:bg-agray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ablue-500"
                    ref={closeNav}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <div className="flex items-center justify-between pb-6 border-b-[1px] border-[#d8d8d8]">
                  <div className="flex">
                    <button
                      onClick={() => changeLanguage('en')}
                      className="whitespace-nowrap text-base font-bold text-agray-500 hover:text-agray-900 mr-2"
                    >
                      EN{' '}
                    </button>
                    <p className="whitespace-nowrap text-base font-bold text-agray-500 hover:text-agray-900">
                      {' '}
                      I{' '}
                    </p>
                    <button
                      onClick={() => changeLanguage('es')}
                      className="text-ablue-900 whitespace-nowrap text-base font-bold text-agray-500 hover:text-agray-900 ml-2"
                    >
                      ES{' '}
                    </button>
                  </div>

                  <div className="-mr-2">
                    <Link
                      onClick={() => closeNav.current?.click()}
                      to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-ablue-600  px-[39px] py-[17px] border rounded-[28px] shadow-sm text-base font-manrope text-white hover:from-purple-700 hover:to-ablue-700"
                    >
                      {t('get-in-touch')}
                    </Link>
                  </div>
                </div>
                <nav className="grid grid-cols-1 gap-7 mt-8">
                  <div className="relative grid gap-3 bg-white pb-8 sm:gap-8 sm:p-8 border-b-[1px] border-[#d8d8d8]">
                    {subnavbar.map((item, index) => (
                      <div key={index}>
                        <h1 className="text-lg text-nblue-900 font-bold">
                          {t(item.title)}
                        </h1>
                        {item.options.map((menu, index) => (
                          <Link
                            to={menu.url}
                            key={index}
                            onClick={() => closeNav.current?.click()}
                          >
                            <h2
                              className={`text-[15px] py-3  my-auto font-serif ${
                                window.location.pathname !== menu.url
                                  ? 'text-agray-500 hover:text-ablue-500'
                                  : 'text-ablue-500'
                              }`}
                            >
                              {' '}
                              {menu.title}
                            </h2>
                          </Link>
                        ))}
                      </div>
                    ))}
                  </div>
                  {solutions.map((item) => (
                    <Link
                      onClick={() => closeNav.current?.click()}
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex items-center rounded-lg hover:bg-ablue-50"
                    >
                      <div
                        className={`text-base font-bold ${
                          window.location.pathname !== item.href
                            ? 'text-ablue-900 hover:text-ablue-500 focus:text-ablue-500'
                            : 'text-ablue-500'
                        }`}
                      >
                        {item.name}
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
