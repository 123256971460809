import 'swiper/css';

import { Link, useLocation } from 'react-router-dom';

import { CheckIcon } from '@heroicons/react/outline';
import ContactUs from './ContactUs';
import FAQs from './FAQs';
import { Helmet } from 'react-helmet';
import Ready from './Ready';
import WeHaveItAll2 from './WeHaveItAll2';
import WhyChooseUs from './WhyChooseUs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ServicesCloudHero(props) {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get('q');

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <>
      <Helmet>
        <title>{t('cloud-services')} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t('cloud-desc')} />
        <meta property="og:title" content={t('cloud-services')} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t('cloud-desc')} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={require('../assets/img/services/hero-cloud.png')}
        />
      </Helmet>
      <main className={`bg-agray-100 pt-40 `}>
        <div className="px-4  md:max-w-3xl lg:max-w-5xl xl:max-w-7xl md:grid  md:grid-cols-2 pb-10 mx-auto">
          <div className="my-auto tv:px-0  md:order-2 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px]">
            <img
              src={require('../assets/img/services/hero-cloud.png')}
              alt="cloud"
            />
          </div>
          <div className="text-left w-full">
            <div className="pb-4">
              <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t('cloud-title')}
              </span>
            </div>
            <h1 className="text-5xl text-nblue-900 font-bold w-4/6">
              {t('cloud-subtitle')}
            </h1>
         
            <div className="mt-9">
              <Link
                to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
                className="hover:bg-nblue-600 bg-ablue-600 md:w-72 flex items-center justify-center px-[22px] py-[17px]  text-sm font-manrope rounded-[28px] text-white"
              >
                <label>{t('next-project')}</label>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-white tv:max-w-none tv:px-[336px] lg:px-32 px-auto">
          <div className="px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
            <div className="relative overflow-hidden pb-[232px]">
              <div className="mt-24 md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-6 items-center">
                <div className="md:order-2">
                  <div className="mt-6">
                    <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t('cloud-desc-title')}
                    </span>
                    <h2 className="text-5xl text-nblue-900 font-bold">
                      {t('cloud-desc-subtitle')}
                    </h2>
                    <p className="font-light mt-4 text-lg text-agray-500 m-auto">
                      {t('cloud-desc')}
                    </p>
                  </div>
                </div>
                <img
                  className="mt-12 md:mt-0 my-auto w-[357.9] h-[404px] lg:w-[489px] lg:h-[553px]"
                  src={require('../assets/img/services/clouddesc.png')}
                  alt="cloud_desc"
                />
              </div>
            </div>
            <div className="relative pb-[127px] overflow-hidden m-auto">
              <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense gap-4 items-center">
                <div>
                  <div className="">
                    <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t('cloud-benefit-title')}
                    </span>
                    <h2 className="text-5xl text-nblue-900 font-bold mt-2">
                      {t('cloud-benefit-subtitle')}
                    </h2>
              
                    <div className="mt-12">
                      <div className="flex gap-[8px]">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('cloud-benefit1')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('cloud-benefit2')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('cloud-benefit3')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('cloud-benefit4')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('cloud-benefit5')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('cloud-benefit6')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="max-w-80 mx-auto my-8 mt-9 md:mt-0 my-auto w-[357.9] h-[404px] lg:w-[489px] lg:h-[553px]"
                  src={require('../assets/img/services/cloudbenefits.png')}
                  alt="cloud_benefits"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 mb-16 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px] mx-auto">
          <div className="pt-16 text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t('tools')}
            </span>
            <h2 className="text-5xl text-nblue-900 font-bold">
              {t('our-teck-stack')}
            </h2>
            <p className="font-light mt-4  text-lg text-agray-500 w-4/5 xl:w-1/2 m-auto  ">
              {t('our-teck-desc')}
            </p>
          </div>

          <div className="py-10">
            <div className="mt-6 grid grid-cols-6  md:grid-cols-6 lg:grid-cols-6 pb-[113px]">
              <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-aws.png')}
                    alt="AWS"
                  />
                  <p className=" text-sm text-agray-500  text-center">
                    Amazon Web Services
                  </p>
                </div>
              </div>
              <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-lambda.png')}
                    alt="AWS_Lambda"
                  />
                  <p className=" text-sm text-agray-500  text-center">
                    AWS Lambda
                  </p>
                </div>
              </div>
              <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-rds.png')}
                    alt="AWS_RDS"
                  />
                  <p className=" text-sm text-agray-500  text-center">
                    AWS RDS
                  </p>
                </div>
              </div>
              <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-googlecloud.png')}
                    alt="googlecloud"
                  />
                  <p className=" text-sm text-agray-500  text-center">
                    Google Cloud Platform
                  </p>
                </div>
              </div>
              <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-digitalocean.png')}
                    alt="Digital_Ocean"
                  />
                  <p className=" text-sm text-agray-500  text-center">
                    Digital Ocean
                  </p>
                </div>
              </div>
              <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                <div>
                  <img
                    className="w-36 my-auto"
                    src={require('../assets/img/services/tool-azure.png')}
                    alt="Microsoft_Azure"
                  />
                  <p className=" text-sm text-agray-500  text-center">
                    Microsoft Azure
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {qParam === 'landing' && <FAQs></FAQs>}
        <WeHaveItAll2 />
      </main>
      {qParam === 'landing' && <WhyChooseUs></WhyChooseUs>}
      {qParam === 'landing' ? <ContactUs></ContactUs> : <Ready></Ready>}
    </>
  );
}
