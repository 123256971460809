import { Fragment, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BlogWidget() {
  SwiperCore.use([Navigation, Autoplay]);

  const navigate = useNavigate();

  let { slug } = useParams();
  const [relatedBlog, setRelatedBlog] = useState([]);
  const { i18n } = useTranslation();

  let items = [...new Map(relatedBlog.map(item => [item.id, item])).values()];

  const fetchBlogData = useCallback(
    async (tag) => {
      fetch(
        `${process.env.REACT_APP_URL}?key=${process.env.REACT_APP_KEY}&filter=tag:${tag}`
      ).then(async response => {
        const data = await response.json();
        var transformedBlog = data.posts.map((blogData) => {
          return {
            id: blogData.uuid,
            slug: blogData.slug,
            title: blogData.title,
            description: blogData.excerpt.replace(/\n/g, ""),
            image: blogData.feature_image,
          }
        });
        let results = [...relatedBlog, ...transformedBlog]
        results = results.filter(function (element) {
          return element !== undefined && element.slug !== slug;
        });
        setRelatedBlog((relatedBlog) => results);
      }).catch(() => {
        throw new Error("Something went wrong");
      });
    }, [slug, relatedBlog]
  );

  useEffect(() => {
    if (i18n.language === "es-ES" || i18n.language === "es") {
      fetchBlogData("es");
    } else {
      fetchBlogData("en");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Fragment>
      <div className="mx-auto w-full bg-green-300 m-4 p-4">
        <Swiper
          className="h-auto bg-green-300"
          slidesPerView={1}
          modules={[Autoplay]}
          autoplay={{ delay: 3500 }}
          spaceBetween={200}
        >
          {items.map((item, index) => (
            <SwiperSlide
              className="h-full flex-row justify-center"
              onClick={(e) =>
                navigate(`/blog/detail/${item.slug}`, { state: { slug: item.slug } })
              }
              key={index}
            >
              <img
                className="w-full rounded-xl max-h-56 object-scale-down"
                src={item.image ?? require("../../assets/img/blog-default.png")}
                alt="cover"
              />
              <div className="text-ablue-900 m-4 font-bold text-2xl text-center">
                {item.title}
              </div>
              <div className="text-agray-500 text-base line-clamp-3 m-4">
                {item.description}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Fragment>
  );
}
