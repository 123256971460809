import { Fragment, useCallback } from "react";
import { useEffect, useState } from "react";

import BlogSuggestions from "./BlogSuggestions";
import { Helmet } from "react-helmet";
import Loading from "../shared/Loading";
import Ready from "../Ready";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BlogDetail() {
  let { slug } = useParams();
  const [loading, setLoading] = useState(true);

  const [blogData, setBlogData] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const fetchBlogDetailData = useCallback(async () => {
    fetch(
      `${process.env.REACT_APP_URL}/slug/${slug}?key=${process.env.REACT_APP_KEY}&include=tags,authors`
    )
      .then(async (response) => {
        const data = await response.json();
        const transformedBlog = data.posts.map((blogData) => {
          return {
            id: blogData.uuid,
            slug: blogData.slug,
            title: blogData.title,
            description: blogData.excerpt.replace(/\n/g, ""),
            image: blogData.feature_image,
            author: blogData.authors.map((authorData) => authorData.name),
            html: blogData.html,
          };
        });
        setBlogData(transformedBlog);
        setLoading(false);
      })
      .catch(() => {
        navigate("page-not-found");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (i18n.language === "es-ES" || i18n.language === "es") {
      fetchBlogDetailData("es");
    } else {
      fetchBlogDetailData("en");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loading]);

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="relative pt-44 pt-16  overflow-hidden m-auto bg-white ">
            {blogData.map((blog) => (
              <div
                className="m-auto top-0  w-11/12 xl:w-7/12 text-center"
                key={blog.id}
              >
                <Helmet>
                  <title>{blog.title}</title>
                  <meta name="description" content={blog.description} />
                  <meta property="og:title" content={blog.title} />
                  <meta property="og:type" content="article" />
                  <meta property="og:image" content={blog.image} />
                  <meta property="og:description" content={blog.description} />
                  <meta property="og:url" content={window.location.href} />
                  <meta
                    property="og:url"
                    content={`https://www.alluxi.com/blog/detail/${slug}`}
                  />
                  <link rel="canonical" href={window.location.href} />
                </Helmet>

                <h1 className="text-4xl xl:text-5xl text-left  text-nblue-900 font-bold">
                  {blog.title}
                </h1>
                <p className="text-md text-left  pt-4 tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                  {t("by")} {blog.author} |{" "}
                  {dateFormat(blog.date, "mmmm dd, yyyy")}
                </p>
                <img
                  src={
                    blog.image ?? require("../../assets/img/blog-default.png")
                  }
                  className="mt-8 h-44 xl:h-96 rounded-xl mx-auto"
                  alt="blog-detail"
                ></img>
                <p className="font-light mt-4 text-lg text-agray-500">
                  <div
                    className="blog_description content text-left"
                    dangerouslySetInnerHTML={{ __html: blog.html }}
                  ></div>
                </p>
              </div>
            ))}

            <div className="relative mt-10 mx-4 xl:mx-16">
              <p className="text-md text-center pt-4 tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t("related-posts")}
              </p>
              <h2 className="text-center text-4xl xl:text-5xl text-nblue-900 font-bold">
                {t("discover-more")}
              </h2>
              <BlogSuggestions></BlogSuggestions>
            </div>
          </div>
          <Ready></Ready>
        </>
      )}
    </Fragment>
  );
}
