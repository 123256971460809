import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

import ClodTech from "./CloudTech";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function MainContent({ noClick, language }) {
  SwiperCore.use([Navigation, Autoplay]);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const services = [
    {
      title: t("custom-web-develop"),
      description: t("custom-web-develop-desc"),
      image: require("../assets/img/home/services-web-color.png"),
      href:
        currentLanguage === "en"
          ? "/services/custom-web-development"
          : "/es/services/custom-web-development",
    },
    {
      title: t("custom-mobile-develop"),
      description: t("custom-mobile-develop-desc"),
      image: require("../assets/img/home/services-mobile-color.png"),
      href:
        currentLanguage === "en"
          ? "/services/custom-mobile-development"
          : "/es/services/custom-mobile-development",
    },
    {
      title: t("nearshore-service"),
      description: t("nearshore-service-desc"),
      image: require("../assets/img/home/services-nearshore-color.png"),
      href:
        currentLanguage === "en"
          ? "/services/nearshore-development"
          : "/es/services/nearshore-development",
    },
  ];

  return (
    <div>
      <div className="relative pt-16 pb-16 m-auto mx-4 md:mx-0">
        <main>
          <div className="m-auto top-0 w-full text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t("services")}
            </span>
            <h2 className="text-5xl text-nblue-900 font-bold">
              {t("what-we-do")}
            </h2>
            <p className="font-light mt-4 xl:text-lg text-agray-500 xl:w-1/2 md:w-3/4 m-auto ">
              {t("what-we-do-desc")}
            </p>
          </div>
          <div className="relative mx-auto md:px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
            <div className="hidden md:grid md:grid-cols-3 md:grid-flow-col-dense md:gap-10">
              {" "}
              <Fade bottom>
                {services.map((service, index) => (
                  <Link
                    to={service.href}
                    key={index}
                    className="hover:scale-105"
                  >
                    <div className="my-4 md:py-16 h-full">
                      <div className="bg-agray-100 h-full w-full rounded-3xl hover:border-ablue-500 hover:border-2">
                        <div className="h-20 w-28 flex pt-16 ml-4 items-center justify-center">
                          <img className="" src={service.image} alt="service" />
                        </div>

                        <div className="mt-6 py-10 px-8">
                          <h3 className="text-2xl text-nblue-900 font-bold">
                            {service.title}
                          </h3>
                          <p className="font-light mt-4 text-lg text-agray-500">
                            {service.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Fade>
            </div>
            <div className="block md:hidden mx-auto w-full">
              <Swiper
                className="h-full"
                slidesPerView={1}
                modules={[Autoplay]}
                autoplay={{ delay: 12000 }}
                spaceBetween={200}
              >
                {services.map((service, index) => (
                  <SwiperSlide
                    className="my-4 bg-agray-100 h-full w-full rounded-3xl"
                    key={index}
                  >
                    <Link to={service.href}>
                      <div className="h-20 w-28 flex pt-16 ml-4 items-center justify-center">
                        <img className="" src={service.image} alt="service" />
                      </div>
                      <div className="mt-6 py-10 px-8">
                        <h2 className="text-2xl text-nblue-900 font-bold">
                          {service.title}
                        </h2>
                        <p className="font-light mt-4 text-lg text-agray-500">
                          {service.description}
                        </p>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="relative mx-auto md:px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
            {!noClick && (
              <>
                <div className="hidden md:block">
                  <div className="mt-24">
                    <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-4">
                      <div className="md:col-start-1">
                        <div>
                          <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                            {t("methodologies")}
                          </span>
                          <h2 className="text-5xl text-nblue-900 font-bold">
                            {t("how-we-work")}
                          </h2>
                          <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                            {t("how-we-work-desc")}
                          </p>
                        </div>
                      </div>
                      <div className="mx-auto my-8 md:my-auto">
                        <Fade bottom>
                          <img
                            className="w-full"
                            src={require("../assets/img/home/how-methodologies.png")}
                            alt="methodologies"
                          />
                        </Fade>
                      </div>
                    </div>
                  </div>
                  <div className="mt-24">
                    <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-4">
                      <div className="order-2">
                        <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                          {t("prototype")}
                        </span>
                        <h2 className="text-5xl text-nblue-900 font-bold">
                          {t("do-it-right")}
                        </h2>
                        <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                          {t("do-it-right-desc")}
                        </p>
                      </div>
                      <div className="w-full mx-auto my-8 md:my-auto">
                        <Fade bottom>
                          <img
                            className=""
                            src={require("../assets/img/home/how-prototype.png")}
                            alt="prototype"
                          />
                        </Fade>
                      </div>
                    </div>
                  </div>
                  <div className="mt-24">
                    <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-4">
                      <div>
                        <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                          {t("MVP")}
                        </span>
                        <h2 className="text-5xl text-nblue-900 font-bold">
                          {t("get-on-road")}
                        </h2>
                        <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                          {t("get-on-road-desc")}
                        </p>
                      </div>
                      <div className="w-full mx-auto my-8 md:my-auto">
                        <Fade bottom>
                          <img
                            src={require("../assets/img/home/how-mvp.png")}
                            alt="mvp"
                          />
                        </Fade>
                      </div>
                    </div>
                  </div>
                  <div className="mt-24">
                    <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-4">
                      <div className="mt-6 order-2">
                        <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                          {t("support")}
                        </span>
                        <h2 className="text-5xl text-nblue-900 font-bold">
                          {t("we-have-back")}
                        </h2>
                        <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                          {t("we-have-back-desc")}
                        </p>
                      </div>
                      <div className="w-full mx-auto my-8 md:my-auto">
                        <Fade bottom>
                          <img
                            className=""
                            src={require("../assets/img/home/how-support.png")}
                            alt="support"
                          />
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block md:hidden mx-auto mt-24">
                  <Swiper
                    className="h-full"
                    slidesPerView={1}
                    modules={[Autoplay]}
                    autoplay={{ delay: 12000 }}
                    spaceBetween={200}
                  >
                    <SwiperSlide>
                      <div className="sm:grid sm:grid-cols-2 sm:grid-flow-col-dense md:gap-4">
                        <div className="md:col-start-1">
                          <div>
                            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                              {t("methodologies")}
                            </span>
                            <h1 className="text-5xl text-nblue-900 font-bold">
                              {t("how-we-work")}
                            </h1>
                            <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                              {t("how-we-work-desc")}
                            </p>
                          </div>
                        </div>
                        <div className="mx-auto my-8 md:my-auto">
                          <Fade bottom>
                            <img
                              className="w-full"
                              src={require("../assets/img/home/how-methodologies.png")}
                              alt="methodologies"
                            />
                          </Fade>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="sm:grid sm:grid-cols-2 sm:grid-flow-col-dense md:gap-4">
                        <div className="order-2">
                          <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                            {t("prototype")}
                          </span>
                          <h2 className="text-5xl text-nblue-900 font-bold">
                            {t("do-it-right")}
                          </h2>
                          <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                            {t("do-it-right-desc")}
                          </p>
                        </div>
                        <div className="w-full mx-auto my-8 md:my-auto">
                          <Fade bottom>
                            <img
                              className=""
                              src={require("../assets/img/home/how-prototype.png")}
                              alt="prototype"
                            />
                          </Fade>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="sm:grid sm:grid-cols-2 sm:grid-flow-col-dense md:gap-4">
                        <div>
                          <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                            {t("MVP")}
                          </span>
                          <h2 className="text-5xl text-nblue-900 font-bold">
                            {t("get-on-road")}
                          </h2>
                          <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                            {t("get-on-road-desc")}
                          </p>
                        </div>
                        <div className="w-full mx-auto my-8 md:my-auto">
                          <Fade bottom>
                            <img
                              src={require("../assets/img/home/how-mvp.png")}
                              alt="mvp"
                            />
                          </Fade>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="sm:grid sm:grid-cols-2 sm:grid-flow-col-dense md:gap-4">
                        <div className="mt-6 order-2">
                          <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                            {t("support")}
                          </span>
                          <h2 className="text-5xl text-nblue-900 font-bold">
                            {t("we-have-back")}
                          </h2>
                          <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                            {t("we-have-back-desc")}
                          </p>
                        </div>
                        <div className="w-full mx-auto my-8 md:my-auto">
                          <Fade bottom>
                            <img
                              className=""
                              src={require("../assets/img/home/how-support.png")}
                              alt="support"
                            />
                          </Fade>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </>
            )}
          </div>
          {!noClick && <ClodTech />}
        </main>
      </div>
    </div>
  );
}
