import { Fragment, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";
import Loading from "./shared/Loading";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Contact(props) {
  const { t, i18n } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);
  const [emailValid, setEmailValid] = useState(false);
  const [emailTouched, setemailTouched] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [messageTouched, setMessageTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const [errorMessage, setErrorMessage] = useState("error-contact-us");

  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();
  const serviceInputRef = useRef();

  const captcha = useRef();

  const currentLanguage = i18n.language;
  

  const navigate = useNavigate();

  const onChange = (e) => {

    if (messageInputRef.current) {

      if (messageInputRef.current.value !== "") {
        setMessageTouched(true);
      }

      let validationMessage = messageInputRef.current.value.length > 10;
      setMessageValid(validationMessage);
    }

    if (emailInputRef.current.value !== "") {
      setemailTouched(true);
      let re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(emailInputRef.current.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
    if (emailInputRef.current.value === "") {
      setEmailValid(false);
    }

    if (
      nameInputRef.current.value !== "" &&
      emailInputRef.current.value !== "" &&
      emailValid === true &&
      emailTouched === true &&
      messageValid === true &&
      messageInputRef.current.value !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const validationEmail = emailTouched && !emailValid;

  const onSubmitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    console.log(captcha.current.getValue());

    if (captcha.current.getValue()) {
      //captcha.current.reset();
      const data = {
        senderEmail: e.target.Email.value,
        subject: "Contact message",
        body: e.target.Message.value,
        name: e.target.Name.value,
        selectedServices: serviceInputRef.current.value,
      };

      const xhr = new XMLHttpRequest();
      xhr.open("POST", process.env.REACT_APP_MAIL_URL);

      xhr.setRequestHeader("x-api-key", process.env.REACT_APP_MAIL_API_KEY);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            nameInputRef.current.value = "";
            emailInputRef.current.value = "";
            messageInputRef.current.value = "";
            serviceInputRef.current.value = "";
            setSent(1);
            setIsDisabled(true);
            setemailTouched(false);
            setLoading(false);

            window.gtag('event', 'conversion', {
              'send_to': 'AW-972900822/JvlkCIaomtYBENaT9c8D'
            });
            window.gtag("event", "conversion", {});
            window.hj("event", "conversion", {});

            navigate(currentLanguage === "en" ? `/thankyou` : `/es/thankyou`);
          } else {
            setSent(2);
            setErrorMessage("error-contact-us");
            setLoading(false);
          }
        }
      };

      xhr.send(JSON.stringify(data));
    } else {
      setSent(2);
      setErrorMessage("error-recaptcha");
      setLoading(false);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <Fragment>
      <Helmet>
        <title>{t("contact")} | Alluxi </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("contact-desc")} />
        <meta property="og:title" content={t("contact")} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("contact-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="pt-16 md:pt-[110px] m-auto mx-4 lg:mx-24">
        <form
          onSubmit={onSubmitHandler}
          method="POST"
          className="mx-auto md:grid grid-cols-2 md:gap-x-4 lg:gap-x-20"
        >
          <div className="col-span-2">
            <p className="text-md  text-center tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t("contact")}
            </p>
          </div>
          <div className="col-span-1">
            <h1 className="pt-2 text-4xl xl:text-4xl text-center text-nblue-900 font-bold">
              {t("tell-us-more")}
            </h1>
            <p className="pt-4 font-light text-center text-lg text-agray-500">
              {t("contact-desc")}
            </p>
            <div className=" grid grid-cols-2 gap-y-6 xl:grid-cols-2 sm:gap-x-8">
              <div className="col-span-2">
                <label
                  htmlFor="name"
                  className="text-md text-nblue-900 font-bold"
                >
                  {t("name")}
                </label>
                <div className="mt-1 ">
                  <input
                    id="name"
                    name="Name"
                    type="text"
                    ref={nameInputRef}
                    onChange={onChange}
                    autoComplete="given-name"
                    placeholder={t("enter-name")}
                    className="pl-6 bg-agray-100 text-nblue-900  h-14 block w-full shadow-sm sm:text-sm focus:ring-nblue-500 focus:border-nblue-500 "
                    data-hj-allow
                  />
                </div>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="email"
                  className="text-md text-nblue-900 font-bold"
                >
                  {t("email")}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="Email"
                    type="email"
                    ref={emailInputRef}
                    onChange={onChange}
                    autoComplete="email"
                    placeholder={t("enter-email")}
                    className="pl-6 text-nblue-900 bg-agray-100 h-14 block w-full shadow-sm sm:text-sm focus:ring-nblue-500 focus:border-ablue-300 border-agray-300 rounded-md"
                    data-hj-allow
                  />
                  {validationEmail && (
                    <p className="text-nblue-500 text-sm">{t("invalid-email")}</p>
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="Message"
                    className="text-md text-nblue-900 font-bold"
                  >
                    {t("message")}
                  </label>
                  <span
                    id="how-can-we-help-description"
                    className="text-sm text-agray-500"
                  >
                    Max. 500 characters
                  </span>
                </div>
                <div className="mt-1">
                  <textarea
                    id="how-can-we-help"
                    name="Message"
                    aria-describedby="how-can-we-help-description"
                    placeholder={t("enter-message")}
                    rows={4}
                    onChange={onChange}
                    ref={messageInputRef}
                    className="text-nblue-900 pl-6 pt-3.5 bg-agray-100 block w-full shadow-sm sm:text-sm focus:ring-nblue-500 focus:border-ablue-300 rounded-md"
                    defaultValue={""}
                    data-hj-allow
                  />
                  {!messageValid && messageTouched && (
                    <p className="text-red-500 text-sm">{t("invalid-message")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <h2 className="text-4xl w-3/4 xl:text-4xl pt-12 md:pt-0 m-auto col-span-2 text-center  text-nblue-900 font-bold">
              {t("which-services")}
            </h2>
            <div className="relative">
              <div className="mt-10 lg:mt-0 md:mx-auto md:max-w-7xl md:grid md:grid-cols-2 lg:grid-flow-col-dense md:gap-6 lg:gap-3 xl:gap-10">
                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 md:px-0">
                  <label>
                    <input
                      type="radio"
                      name="Service"
                      id="web"
                      ref={serviceInputRef}
                      value="Web Development"
                      className="hidden peer"
                      defaultChecked
                    ></input>
                    <div className="bg-agray-100 h-64 w-full rounded-xl text-nblue-900 border-2 border-transparent peer-checked:border-ablue-600 peer-checked:text-ablue-500 duration-200">
                      <div className="h-20 w-28 flex pt-16 items-center justify-center">
                        <img
                          className=""
                          src={require("../assets/img/home/services-web-color.png")}
                          alt="service web"
                        />
                      </div>
                      <div className="mt-[40px] p-6 xl:p-8">
                        <h2 className="text-left text-2xl md:text-xl lg:text-2xl font-bold font-manrope md:w-1/2">
                          {t("web-develop")}
                        </h2>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="mt-8 md:mt-0 px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 md:px-0 ">
                  <label>
                    <input
                      type="radio"
                      name="Service"
                      ref={serviceInputRef}
                      id="mobile"
                      value="Mobile Development"
                      className="hidden peer"
                    ></input>
                    <div className="bg-agray-100 h-64 w-full rounded-xl text-nblue-900 border-2 border-transparent peer-checked:border-ablue-600 peer-checked:text-ablue-500 duration-200">
                      <div className="h-20 w-28 flex pt-16 ml-4 items-center justify-center">
                        <img
                          className=""
                          src={require("../assets/img/home/services-mobile-color.png")}
                          alt="service mobile"
                        />
                      </div>
                      <div className="mt-[40px] p-6 xl:p-8">
                        <h2 className="text-left text-2xl  md:text-xl lg:text-2xl font-bold font-manrope">
                          {t("mobile-develop")}
                        </h2>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 mt-1">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              ref={captcha}
            />
            {loading ? (
              <div className="flex">
                <div className="justify-center m-auto">
                  <Loading></Loading>
                </div>{" "}
              </div>
            ) : (
              <div className="text-center m-auto col-span-2">
                <button
                  disabled={isDisabled}
                  type="submit"
                  className={`mt-4 inline-flex justify-center py-3 px-14 border border-transparent shadow-sm text-sm font-medium rounded-full text-white ${
                    isDisabled
                      ? "bg-agray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-nblue-500"
                      : "bg-ablue-600"
                  }`}
                >
                  {t("send")}
                </button>
                {sent === 1 && (
                  <p className="font-light text-center mt-4  text-lg text-agray-500 ">
                    {" "}
                    {t("thanks-contacting-us")}
                  </p>
                )}
                {sent === 2 && (
                  <p className="font-light text-center mt-4  text-lg text-agray-500 ">
                    {" "}
                    {t(errorMessage)}
                  </p>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
}
